import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../styles/theme';

function AnimatedTextOnHover({ text, styling, child, hoverColor, textColor, showLineAnimation }) {
  return (
    <StyledContainer data-hover-text={text} styling={styling} hoverColor={hoverColor} textColor={textColor}>
      <span>{text}</span>
      {child}
     { showLineAnimation && <LineAnimation /> }
    </StyledContainer>
  );
}

function LineAnimation() {
  return (
    <LineAnimationContainer style={{display: 'block'}}>
      <div></div>
    </LineAnimationContainer>
  );
}

export default AnimatedTextOnHover;

const lineAnimation = keyframes`
  0% { transform: translateX(-100%) }
  80% { transform: translateX(200%) }
  100% { transform: translateX(200%) }
`;

const StyledContainer = styled.div`
    margin-right: 2rem;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    font-size: 13px;
    font-family: Montserrat, sans-serif;
    color: ${({textColor}) => textColor || '#fff'};
    ${({ styling }) => `${styling || ''}`}
    & > div { display: none; }
    &:hover {
      & > div { display: block; }
      & > span {
        transform: translateY(-12px);
        opacity: 0;
      }
      &:after {
        transform: translateY(0);
        opacity: 1;
      }
    }
    &:after {
      content: attr(data-hover-text);
      position: absolute;
      transform: translateY(12px);
      cursor: pointer;
      left: 0;
      opacity: 0;
      ${({ hoverColor }) => hoverColor && `color: ${hoverColor};`}
      transition: transform 0.4s ease, opacity 0.4s ease;
    }
    & > span {
      cursor: pointer;
      transform: translateY(0);
      transition: transform 0.4s ease, opacity 0.4s ease;
    }
`;

const LineAnimationContainer = styled.div`
  position: absolute;
  top: calc(50% + 14px);
  width: 100%;
  overflow: hidden;
  & > div {
    width: 100%;
    height: 2px;
    background: ${theme.primary};
    animation: ${lineAnimation} 2.5s ease-out infinite;
  }
`;
