import React, { useState, useEffect } from 'react';
import firebase from '../firebaseConfig';

export const AuthContext = React.createContext({
  user: null,
  updateUserDetails: null,
  loading: true,
});

function Authenticate({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const updateUserDetails = user => { setUser(user); };

  useEffect(() => {
    const unsubscribeAuth = firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        // console.log('currentUser: ', user );
        setUser(user);
      } else {
        // No user is signed in.
        // console.log('currentUser: ', user );
        setUser(null);
      }
      setLoading(false);
    });

    // clean up code
    return () => {
      unsubscribeAuth();
    }
  }, []);

  return (
    <AuthContext.Provider value={{
      user,
      updateUserDetails,
      loading,
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export default Authenticate;