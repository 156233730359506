import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { database } from 'firebase';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../styles/theme';
import FancyButton from '../components/FancyButton';
import Loading from '../components/Loading';
import {BlackButton} from '../styles/CommonStyles';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function Bangalore(props) {
  const [network, setNetwork] = useState({});
  const [selectedCity, setCity] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "MyMovers in Bangalore, Karnataka";

    // fetch data from firebase
    database().ref('/network').once('value', snap => {
      const data = snap.val();
      setNetwork(data);
      setLoading(false);
      setCity(Object.keys(data)[0]);
    })

  }, []);

  if(loading) {
    return <Loading />
  }

  const DisplayBranchAddress = (e, city) => {
    e.preventDefault();
    if(city === "Jaipur") { 
      props.history.push(`/packers-and-movers-in-jaipur`);
      return;
    }  else if(city === "Jagatpura") { 
      props.history.push(`/packers-and-movers-in-jagatpura-jaipur`);
      return;
    } else if (city === "Bangalore") {
      props.history.push(`/packers-and-movers-in-bangalore`);
      return;
    } else if (city === "Whitefield") {
      props.history.push(`/packers-and-movers-in-whitefield-bangalore`);
      return;
    } else {
      setCity(city);
      return;
    }
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '1rem',
          color: theme.primary
        }}>Packers and Movers in Bangalore, Karnataka</h1>
        <h3><a href="https://mymovers.in">MyMovers.in</a> | Calculate movers and packers charges in Bangalore</h3>
        <h4>A Leading Packers and Movers Company in Bangalore </h4>
        <p>My Movers are the most trusted Packers and Movers in Bangalore For almost 25 years our name has been 
          synonymous with high quality Domestic, Household, Office, Car/Bike, Corporate, and Local Shifting. 
          My Movers Packers and Movers has grown to become one of the largest and most respected providers of 
          Packing and moving services in Bangalore, handling over millions moves every year. We the top packers and 
          movers Bangalore guarantees absolute quality of service to its clients. 
        </p>

        <p>My Movers packers and movers in bangalore provides the best services all over the bangalore.
          Maximum client's fulfillment is at our supremacy and hence we understand the requirements of safely moving your 
          valuable possessions from one place to another destination, be it to and from any two outrageous corners. It has 
          helped us in keeping up our presence by adding other services to our packing and moving services in Bangalore.</p>

        <h4>Professional Packers and Movers Service in Bangalore</h4>
        <p>
          <li>Relocation Services</li>
          <li>Household Shifting</li>
          <li>Office Shifting</li>
          <li>Luggage Transport</li>
          <li>Car Transport</li>
          <li>Bike Transport</li>
          <li>Warehousing</li>
          <li>International Shifting</li>
        </p>

        <h4>Professional Packing and Moving Service in Bangalore</h4>
        <p>Majority of the people and businesses across the country order our services as it is easy and reasonable to 
          booking, and we shift everything right from cars to furniture and households even if it’s going local Shifting 
          Service in Bangalore. Adding to this, we the Movers and Packers in Bangalore offer:
          <li>Fast Transportation service in Bangalore. We are especially cantered on providing our clients with the best value 
            and on time relocation solutions for all sorts of requirements.</li>
          <li>Incredible supervision packing of every single household or office item is done under the supervision of an expert 
            and thus ensures that no single damage occurs in your valuable items.</li>
          <li>Safety and reliability all delivery of orders or shipments of goods is done very correctly. My Movers Packers and 
            Movers in Bangalore  are very well known for offering safe and secure delivery of items to any desired destination 
            in India.</li>
          <li>24/7 SERVICES You get the right Unit behind you and work 24x7 hours reducing all the unnecessary stress, generally 
            associated with home or office relocation.</li>
          <li>No Hidden Charges Cheapest isn't a generally the best, but My Movers Packers and Movers provide you the right and 
            most genuine rates and never give you any false price quotations.</li>
        </p>
        <h4>Goods Transport Services Bangalore-My Movers Packers and Movers Bangalore Packers and Movers in Bangalore</h4>
        <p>My Movers Packers and Movers Provide a Quality service affordable price we know that you will always to get your 
          items delivered to your doorstep in Bangalore. The desired time and in affordable packers and movers cost.
          therefore, our well trained professional's employee always tries to deliver your goods before the scheduled time.</p>
        <h4>Why the My Movers Packers is the Best Packers and Movers in Bangalore?</h4>
        <li>Trained & professional staff</li>
        <li>Competitive pricing</li>
        <li>Fast & efficient</li>
        <li>Fully insured</li>
        <li>Stress-free moving</li>
        <li>All-inclusive services</li>

        <h4>Book Now</h4>
        <p>100% satisfied customers our aim is to stand on the expectations of our clients, customers by moving their costly 
          and valuable items / goods safely, securely without any risk of breakage or damage to their desire destination.</p>
        <h4>Why one should choose My Movers Packers and Movers?</h4>
        <p>We value the client and his valuable belongings. My Movers Packers and Movers have the appropriate vehicle carrier 
          which can load the car/bike in your presence at your home and similarly can deliver the same at your new location.</p>

        <h4>Superior service areas in Bangalore</h4>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Whitefield</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Marathalli</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Yelahanka</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Hebbal</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Banaswadi</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">JP Nagar</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">KR Puram</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Yelahanka</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Varthur</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">HSR Layout</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Electronic City</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Bellandur</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Sarjapur</a></li>
        <li><a href="/packers-and-movers-in-whitefield-bangalore">Yashwanthpur</a></li>
        
        <StyledButton>
          <Link to="/calculate-packers-and-movers-charges">
            <BlackButton style={{textTransform: 'uppercase'}}>Calculate Moving Cost</BlackButton>
          </Link>
        </StyledButton>

        <br/>
        <h3>Whitefield, Bangalore Office address</h3>
        <p style={{marginBottom: '3rem'}}>  
          <b>MyMovers Packers and Movers</b><br/>
          156, Nandishwara Nilaya, 3rd Cross Rd, Hagadur, 
          Immadihalli, Whitefield, Vinayakanagara (Danadamande),
          Bengaluru, Karnataka 560066 <br/>
          <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-9116550911">+91-9116550911</a> <br />
          Email ID: <a href="mailto:info@mymovers.in">info@mymovers.in</a> <br />
          Website: <a href="https://mymovers.in">MyMovers.in</a>
        </p>

        <CityContainer>
        {
            Object.keys(network).map((city, index) => (
              <FancyButton
                text={city}
                key={index}
                onClick={(e) => DisplayBranchAddress(e, city)}
                active={selectedCity === city}
              />
            ))
          }
        </CityContainer>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Bangalore;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 4rem;
  font-family: Montserrat, serif;
  p {
    text-align: justify;
    text-justify: inter-word;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;

const CityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;