import React, { useState } from 'react';
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";

import {
  StyledButton,
  ButtonContainer,
  FadeInUpAnimation,
  StyledSelect
} from '../../styles/CommonStyles';
import Header from '../Header';
import { auth } from 'firebase';

const maxFloors = 60;
const floorNames = [{ key: 0, name: "Ground Floor" }];
for(var i = 1; i <= maxFloors; i++) {
  let suffix = "th";
  const remainder = i % 10;
  if(remainder === 1) suffix = "st";
  if(remainder === 2) suffix = "nd";
  if(remainder === 3) suffix = "rd";
  floorNames.push({ key: i, name: i+suffix });
}
floorNames[10] = {key: 11, name: '11th'};
floorNames[11] = {key: 12, name: '12th'};
floorNames[12] = {key: 13, name: '13th'};
// console.log(floorNames);

function LiftAvailability(props) {
  const [currentLocationFloor, setCLFloor] = useState(() => localStorage.getItem('currentLocationFloor') || "Ground Floor");
  const [currentLocationLift, setCLLift] = useState(() => localStorage.getItem('currentLocationLift') || "not_available");
  const [movingLocationFloor, setMLFloor] = useState(() => localStorage.getItem('movingLocationFloor') || "Ground Floor");
  const [movingLocationLift, setMLLift] = useState(() => localStorage.getItem('movingLocationLift') || "not_available");

  const submitForm = e => {
    const keys = ['currentLocationFloor', 'currentLocationLift', 'movingLocationFloor', 'movingLocationLift'];
    const liftAvailability = {
      currentLocationFloor: currentLocationFloor,
      currentLocationLift: currentLocationLift,
      movingLocationFloor: movingLocationFloor,
      movingLocationLift: movingLocationLift  
    }

    keys.forEach(key => {
      if(!localStorage.getItem(key)) localStorage.setItem(key, liftAvailability[key]);
    });

    if(auth().currentUser) {
      props.history.push('/item-details', { formsPreFilled: true });
    } else {
      props.history.push('/login', { formsPreFilled: true });
    }
  }

  return (
    <React.Fragment>
      <Header {...props} />
      <Container>
        <Form>
          <h2 style={{marginTop: 0}}>I currently live on</h2>
          <FormGroup>
            <StyledLabel>floor</StyledLabel>
            <StyledSelect value={currentLocationFloor} onChange={({ target: { value } }) => {
              setCLFloor(value)
              localStorage.setItem('currentLocationFloor', value)
            }}>
              {floorNames.map(({ name, key }, i) => (
                <option key={"floor-from"    + key + i}>{name}</option>
              ))}
            </StyledSelect>
          </FormGroup>
          <FormGroup>
            <StyledLabel>with service lift</StyledLabel>
            <StyledSelect value={currentLocationLift} onChange={({ target: { value } }) => {
              setCLLift(value);
              localStorage.setItem('currentLocationLift', value);
            }}>
              <option value={"not_available"}>not available</option>
              <option value={"available"}>available</option>
            </StyledSelect>
          </FormGroup>

          <h2 style={{marginTop: 0}}>I'm moving to</h2>
          <FormGroup>
            <StyledLabel>floor</StyledLabel>
            <StyledSelect value={movingLocationFloor} onChange={({ target: { value } }) => {
              setMLFloor(value);
              localStorage.setItem('movingLocationFloor', value);
            }}>
              {floorNames.map(({ name, key }, i) => (
                <option value={name} key={"floor-to" + key + i}>{name}</option>
              ))}
            </StyledSelect>
          </FormGroup>
          <FormGroup>
            <StyledLabel>with service lift</StyledLabel>
            <StyledSelect value={movingLocationLift} onChange={({ target: { value } }) => {
              setMLLift(value);
              localStorage.setItem('movingLocationLift', value);
            }}>
              <option value={"not_available"}>not available</option>
              <option value={"available"}>available</option>
            </StyledSelect>
          </FormGroup>
          <ButtonContainer style={{margin: 0}}>
            <StyledButton onClick={submitForm}>Next</StyledButton>
          </ButtonContainer>
        </Form>
      </Container>
    </React.Fragment>
  );
}

export default LiftAvailability;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 10vh;
  margin-bottom: 10vh;
`;

const Form = styled.form`
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  padding: 2rem;
  border-radius: 8px;
  animation: ${FadeInUpAnimation} 0.45s;
  font-family: Montserrat, sans-serif;
  width: 95vw;
  max-width: 350px;

  @media only screen and (max-width: 800px) {
    box-shadow: none;
    padding: 8px;
    border: none;
    margin-top: 2rem;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: Montserrat, sans-serif;
  margin: 1.7rem 0 2rem 0;
`;

const StyledLabel = styled.label`
  position: absolute;
  top: -4px;
  left: 1rem;
  z-index: 1;
  background: #fff;
  padding: 0 4px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
`;
