import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import firebase from 'firebase';
import swal from 'sweetalert';
import "react-datepicker/dist/react-datepicker.css";

import {
  StyledInput,
  StyledButton,
  ButtonContainer,
  FadeInUpAnimation,
  StyledSelect,
  ErrorText,
  Loader,
  FadeInRight,
  FormGroup,
  StyledLabel,
  FlexRowCenter,
  headerHeight
} from '../../styles/CommonStyles';
import Header from '../Header';
import { AuthContext } from '../Auth/Authenticate';

window.moment = moment;

function LocationDetails(props) {
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [moveType, setMoveType] = useState(() => localStorage.getItem('moveType') || "household_goods");
  const [date, setDate] = useState(() => {
    const dateFromLs = localStorage.getItem('movingDate');
    return dateFromLs && moment(dateFromLs).isSameOrAfter(moment()) ? moment(dateFromLs)._d : "";
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [nameErr, setNameErr] = useState(null);
  const [phoneErr, setPhoneErr] = useState(null);
  const [emailErr, setEmailErr] = useState(null);
  const [bhkErr, setBhkErr] = useState(null);
  const [fromErr, setFromErr] = useState(null);
  const [toErr, setToErr] = useState(null);
  const [dateErr, setDateErr] = useState(null);

  const [fromLoading, setFromLoading] = useState(false);
  const [toLoading, setToLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [authConf, setAuthConf] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOTP] = useState('');

  const authContext = useContext(AuthContext);
  const mainRef = useRef();
  const ref2 = useRef();

  useEffect(() => {
    document.title = "Book Now";
    if(mainRef && mainRef.current) {
      mainRef.current.focus();
    }

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('log_in_button', {
      'size': 'invisible',
      'callback': function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // console.log('recapcha done...');
        // sendMobileOtp();
      }
    });
  }, []);

  const validateForm = () => {
    let isValidated = true;

    const isUserLoggedIn = Boolean(authContext.user);

    // if user is not logged in validate name, mail, phone nos
    if(!isUserLoggedIn) {
      if(!name) {
        setNameErr('name is required');
        isValidated = false;
      }
  
      if(!email) {
        setEmailErr('email is required, will be used to send order confirmation');
        isValidated = false;
      }
  
      if(!phone) {
        setPhoneErr('phone is required');
        isValidated = false;
      }

      if(phone && String(phone).length !== 10) {
        setPhoneErr('invalid phone number');
        isValidated = false;
      }
  
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(email && !mailformat.test(email)) {
        setEmailErr('invalid email');
        isValidated = false;
      }
    }

    if(!moveType) {
      setBhkErr('missing field');
      isValidated = false;
    }

    if(!from) {
      setFromErr('missing from location');
      isValidated = false;
    }

    if(!to) {
      setToErr('missing to location');
      isValidated = false;
    }

    if(!date) {
      setDateErr('missing date');
      isValidated = false;
    }

    return isValidated;
  }

  const saveProfileAndSendOTP = e => {
    e.preventDefault(); 
    if(!loading) {
      const isValidated = validateForm();
      if(isValidated) {
        // console.log(phone, email, name, moveType, from, to, date);

        if(authContext.user) {
          props.history.push('/lift-availability');
          return;
        }

        setSaving(true);

        // send OTP to the user
        const appVerifier = window.recaptchaVerifier;
        firebase.auth().signInWithPhoneNumber("+91" + phone, appVerifier)
          .then(confirmationResult => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            setAuthConf(confirmationResult)
            setOtpSent(true);
          })
          .catch(error => { swal(error.message); })
          .finally(() => { setSaving(false); })


        // notify mymover in separate async call
        const api = 'https://us-central1-mymovers-c9a28.cloudfunctions.net';
        const url = `${api}/sendSignupEmailTest?name=${name}&email=${email}&phone=${phone}&from=${from.description}&to=${to.description}&moveType=${moveType}&date=${date || localStorage.getItem('movingDate')}`;
        //const url = `${api}/sendSignupEmail?name=${name}&email=${email}&phone=${phone}`;
        Axios.get(url)
          .then(data => {
            console.log('mymover notified!!');
          })
          .catch(err => {
            console.log('err while notifying mymover...', err);
          });
      }
    }
  }

  const handleName = ({ target: { value } }) => { setName(value); }
  const handleEmail = ({ target: { value } }) => { setEmail(value); }
  const handlePhone = ({ target: { value } }) => {
    if(value.length <= 10) {
      setPhone(value);
    }
  }

  const handleOtpChange = e => {
    const { value } = e.target;
    if(value.length <= 6 && (/\d$/.test(value) || value === "")) {
      setOTP(value);
    }
  }

  const confirmOTP = e => {
    e.preventDefault();
    if(!loading) {  // prevent multiple clicks
      setLoading(true);
      authConf.confirm(otp).then(async function (result) {
        setAuthConf(null);
        const user = result.user;
        authContext.updateUserDetails(user);
        /*console.log("props: ", props);
        const propLocation = props.location;
        console.log("location state: ", propLocation.pathname);
        if (propLocation && propLocation !== undefined && !propLocation.state && propLocation.state !== undefined && propLocation.state.formsPreFilled) {
          console.log("inside location");
          setLoading(false);
          props.history.push('/item-details');
        } else {*/
        const uid = firebase.auth().currentUser.uid;
        // get user from /profiles
        const snap = await firebase.database().ref(`/profiles/${uid}`).once('value')
        const resValue = snap.val();
        if(!resValue) {
          await firebase.database().ref(`/`).update({
            [`profiles/${uid}`]: {
              name,
              email,
              phone
            },
            [`accounts/+91${phone}`]: phone,
          })
          .then(() => {
            setLoading(false);
            props.history.push('/lift-availability');
          })
          .catch(err => {
            swal(`Error: ${err.message}`);
          })
        } else {
          setLoading(false);
          props.history.push('/lift-availability');
        }
      }).catch(function (error) {
        console.log('error', error);
        setLoading(false);
        if(error.code === "auth/invalid-verification-code") {
          alert(`Incorrect OTP`);
        } else {
          alert(`Verification failed. Reason: ${error.message}`);
        }
      });
    }
  }

  if(otpSent) {
    return (
      <React.Fragment>
        <OTPContainer>
          <InnerContainer>
            <FadeInRight>
              <h2 style={{ marginBottom: 0 }}>Verify OTP</h2>
              <p style={{ marginBottom: '2.5rem' }}>OTP sent to your number</p>
              <form>
                <FormGroup>
                  <StyledLabel>OTP</StyledLabel>
                  <StyledInput
                    placeholder={"enter one time password"}
                    value={otp}
                    onChange={handleOtpChange}
                    style={{textAlign: 'center'}}
                    ref={ref2}
                  />
                </FormGroup>
                <ButtonContainer>
                  <StyledButton
                    onClick={confirmOTP}
                    loading={loading}
                  >{ loading ? "Signing In..." : "Submit" }</StyledButton>
                </ButtonContainer>
              </form>
            </FadeInRight>
          </InnerContainer>
        </OTPContainer>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Header {...props} />
      <Container>
        <Form>
          {
            !authContext.user && (
              <React.Fragment>
                <FormGroup>
                  <StyledLabel>Name</StyledLabel>
                  <StyledInput
                    value={name}
                    onChange={handleName}
                    placeholder={"Enter your name"}
                    style={{padding: '8px 15px'}}
                    ref={mainRef}
                    tabIndex={1}
                  />
                </FormGroup>
                <ErrorText>{nameErr}</ErrorText>
                <FormGroup>
                  <StyledLabel>Phone</StyledLabel>
                  <StyledInput
                    value={phone}
                    type={"number"}
                    onChange={handlePhone}
                    placeholder={"Enter phone number"}
                    style={{padding: '8px 15px'}}
                    tabIndex={2}
                  />
                </FormGroup>
                <ErrorText>{phoneErr}</ErrorText>
                <FormGroup>
                  <StyledLabel>Email</StyledLabel>
                  <StyledInput
                    value={email}
                    onChange={handleEmail}
                    placeholder={"Enter email"}
                    style={{padding: '8px 15px'}}
                    tabIndex={3}
                  />
                </FormGroup>
                <ErrorText>{emailErr}</ErrorText>
              </React.Fragment>
            )
          }
          {/* <h2 style={{marginTop: 0}}>I am shifting my</h2> */}
          <FormGroup>
            <StyledLabel>Move Type</StyledLabel>
            <StyledSelect
              value={moveType}
              tabIndex={4}
              onChange={({target: {value}}) => {
                localStorage.setItem('moveType', moveType)
                setMoveType(value);
              }}
            >
              <option value={"household_goods"}>Household Goods</option>
              <option value={"household_goods_and_vehicle"}>Household goods and Vehicle</option>
              <option value={"car_transport"}>Car Transport</option>
              <option value={"bike_transport"}>Bike Transport</option>
            </StyledSelect>
          </FormGroup>
          <ErrorText>{bhkErr}</ErrorText>
          <FormGroup>
            <StyledLabel>FROM</StyledLabel>
            <DropDownLocation
              localStorageName={"from-location"}
              tabIndex={5}
              setLocation={setFrom}
              placeholder={"moving from location"}
              setLoading={setFromLoading}
            />
            {
              fromLoading && (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )
            }
          </FormGroup>
          <ErrorText>{fromErr}</ErrorText>
          <FormGroup>
            <StyledLabel>TO</StyledLabel>
            <DropDownLocation
              localStorageName={"to-location"}
              tabIndex={6}
              setLocation={setTo}
              placeholder={"moving to location"}
              setLoading={setToLoading}
            />
            {
              toLoading && (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )
            }
          </FormGroup>
          <ErrorText>{toErr}</ErrorText>
          <DateContainer>
            <StyledLabel>ON</StyledLabel>
            <StyledDatePicker
              tabIndex={7}
              selected={date}
              onChange={date => {
                localStorage.setItem('movingDate', date);
                setDate(date);
              }}
              placeholderText={"date"}
              minDate={new Date()}
            />
          </DateContainer>
          <ErrorText>{dateErr}</ErrorText>
          <ButtonContainer style={{margin: 0, marginTop: '2rem'}}>
            <StyledButton
              loading={loading || saving}
              onClick={saveProfileAndSendOTP}
            >{(loading || saving) ? "Please wait..." : "Next"}</StyledButton>
          </ButtonContainer>
        </Form>
        <div id="log_in_button" style={{display: 'none'}}></div>
      </Container>
    </React.Fragment>
  );
}

let interval = null;

function DropDownLocation({ setLocation, placeholder, localStorageName, setLoading, tabIndex }) {
  const [value, setValue] = useState("");
  // const [typing, setTyping] = useState(true);
  const [visible, setVisible] = useState(false);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const lsString = localStorage.getItem(localStorageName);
    if(lsString && lsString[0] === "{") { // check if data is an object...
      const locationDetails = JSON.parse(lsString);
      if(typeof locationDetails === "object") {
        console.log("locationDetails: ", locationDetails);
        setValue(locationDetails.description);
        setLocation(locationDetails);
      }
    }
  }, []);

  const getPlace = (searchText) => {
    setLoading(true);
    const url = `https://us-central1-mover-1.cloudfunctions.net/getPlaces?input=${searchText}`;
    Axios.get(encodeURI(url))
      .then(({ data: { places } }) => {
        setVisible(Boolean(searchText));
        const placeData = places.map(({ description, place_id }) => ({ description, place_id }));
        // console.log("place: ", placeData);
        setLocations(placeData);
        setLoading(false);
      })
      .catch(err => {
        console.log('err in place fetch', err);
        setLoading(false);
        setVisible(false);
      })
  }

  const handleInputChange = ({ target: { value }}) => {
    if(interval) {
      clearInterval(interval);
    }

    interval = setTimeout(() => {
      // setTyping(false);
      if(value && value.length > 2) {
        getPlace(value);
      } else {
        setVisible(false);
        setLocation(null);
      }
      clearTimeout(interval);
    }, 250);

    // setTyping(true);
    setValue(value);
  }

  return (
    <DropDownContainer>
      <StyledInput
        value={value}
        onChange={handleInputChange}
        isDropdown={visible}
        tabIndex={tabIndex}
        placeholder={placeholder}
        style={{padding: '8px 15px'}}
      />
      { locations && visible && (
        <ListOfLocations tabIndex={2}>
          {locations.map(({description, place_id}, i) => (
            <ListItem
              key={"location" + place_id + i}
              title={description}
              onClick={() => {
                localStorage.setItem(localStorageName, JSON.stringify({description, place_id}));
                if(localStorage.getItem('distanceInMeters')) localStorage.removeItem('distanceInMeters');
                setValue(description);
                setVisible(false);
                setLocation({description, place_id});
              }}
            >{description}</ListItem>
          ))}
        </ListOfLocations>
      )}
    </DropDownContainer>
  );
}

export default LocationDetails;

const DateContainer = styled.div`
  margin-top: 2rem;
  position: relative;
  & > div { width: calc(100% - 34px); }
`;

const StyledDatePicker = styled(DatePicker)`
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 13px 15px;
  width: 100%;
  font-family: Montserrat,sans-serif;
  font-weight: 500;
  color: #202124;
  font-size: 16px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 10vh;
  margin-bottom: 10vh;
  @media only screen and (max-width: 800px) {
    padding-top: 2vh;
  }
`;

const OTPContainer = styled(FlexRowCenter)`
  width: 100%;
  height: calc(100vh - ${headerHeight});
  font-family: Montserrat, sans-serif;
`;

const InnerContainer = styled(FlexRowCenter)`
  padding: 48px 40px 36px;
  max-width: 400px;
  max-height: 400px;
  border: 1.3px solid #ddd;
  border-radius: 4px;

  @media only screen and (max-width: 460px) {
    padding: 24px 24px 36px;
    border: none;
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 30%;
  right: 1rem;
`;

const Form = styled.form`
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  padding: 2rem;
  border-radius: 8px;
  animation: ${FadeInUpAnimation} 0.45s;
  font-family: Montserrat, sans-serif;

  @media only screen and (max-width: 800px) {
    box-shadow: none;
    padding: 8px;
    border: none;
    margin-top: 2rem;
  }
`;

const DropDownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ListOfLocations = styled.ul`
  position: absolute;
  top: 42px;
  left: 1px;
  padding: 0;
  list-style: none;
  width: calc(100% - 6px);
  border-radius: 0 0 4px 4px;
  border: 2px solid #ddd;
  border-top: none;
  background: #fff;
  z-index: 6;

  & > li:last-child { border: none; }
`;

const ListItem = styled.li`
  width: calc(100% - 20px);
  padding: 16px 12px;
  transition: background-color 0.15s ease;
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  font-weight: 500;

  :hover {
    background-color: rgba(0, 0, 0, .06);
  }
`;
