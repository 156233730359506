import React from 'react';
import styled from 'styled-components';

function NotFound(props) {
  return (
    <Container class="error-contents">
      <svg viewBox="0 0 429.4 301.63" xmlns="http://www.w3.org/2000/svg">
        <path fill="#009688" class="cls-1" d="M353.1 7c-60.4-21.62-127.4 8.47-149.65 67.22-15.42 40.74-5.28 84.56 22.53 114.8a114.63 114.63 0 0 0 15.17 13.8c9.74 10.83-17.63 23-28.06 26.84a1 1 0 0 0 .22 1.96c68.73 7.63 114.1-2.8 137.62-11.1l.98-.36.56-.2q5.14-1.8 10.07-4.08a80.2 80.2 0 0 0 7.25-3.65 113.82 113.82 0 0 0 52.34-59.66C444.42 93.82 413.5 28.65 353.1 7z"></path>
        <path fill="#009688" class="cls-2" d="M348.8 7a113.38 113.38 0 0 0-108.87 195.83c9.47 10.82-17.16 22.98-27.3 26.83a1.02 1.02 0 0 0 .27 1.96c66.85 7.63 110.98-2.8 133.86-11.1l.95-.36.6-.2q5-1.8 9.8-4.08a77.23 77.23 0 0 0 7.05-3.65A113.33 113.33 0 0 0 348.8 7z"></path>
        <path fill="#fff" class="cls-3" d="M31.9 278.16a4.96 4.96 0 0 1-4.6-4.92v-99.46a4.7 4.7 0 0 1 4.6-4.62h130.67a4.82 4.82 0 0 1 4.74 4.62v99.46a5.1 5.1 0 0 1-4.7 4.92z"></path>
        <path fill="#009688" class="cls-2" d="M162.57 170.16a3.72 3.72 0 0 1 3.74 3.62v99.46a3.98 3.98 0 0 1-3.7 3.92H31.9a3.85 3.85 0 0 1-3.6-3.92v-99.46a3.6 3.6 0 0 1 3.6-3.62h130.4m.27-2H31.9a5.8 5.8 0 0 0-5.6 5.62v100.46c0 2.96 2.64 4.92 5.6 4.92h130.67c2.96 0 5.74-1.96 5.74-4.92V173.78a5.92 5.92 0 0 0-5.7-5.62z"></path>
        <path fill="#e1ebef" class="cls-4" d="M164.3 260.4v10.5c0 1.96-1.95 3.26-3.9 3.26H34.07a3.5 3.5 0 0 1-3.75-3.26v-97.2a3.73 3.73 0 0 1 3.76-3.54H160.4a3.86 3.86 0 0 1 3.9 3.53v86.7z"></path>
        <path fill="#e1ebef" class="cls-4" d="M32.37 170.16H162a2.3 2.3 0 0 1 2.3 2.3v15.7h-134v-15.94a2.06 2.06 0 0 1 2.07-2.06z"></path>
        <path fill="#009688" class="cls-5" d="M28.3 188.16h138v2h-138z"></path>
        <circle fill="#a2b7bc" class="cls-6" cx="39.18" cy="179.11" r="2.52"></circle>
        <circle fill="#a2b7bc" class="cls-6" cx="47" cy="179.11" r="2.52"></circle>
        <circle fill="#a2b7bc" class="cls-6" cx="54.82" cy="179.11" r="2.52"></circle>
        <path fill="#a9a9a9" class="cls-7" d="M109.65 254.16a3.94 3.94 0 0 0 3.6-5.02 17.14 17.14 0 0 0-33 .06 3.9 3.9 0 0 0 3.6 4.96z"></path>
        <path fill="#a9a9a9" class="cls-7" d="M109.65 254.16a3.94 3.94 0 0 0 3.6-5.02 17.14 17.14 0 0 0-33 .06 3.9 3.9 0 0 0 3.6 4.96z"></path>
        <path fill="#9c9b9b" class="cls-8" d="M106.83 254.16a3.08 3.08 0 0 0 2.83-3.92 13.4 13.4 0 0 0-25.8.04 3.04 3.04 0 0 0 2.82 3.88z"></path>
        <circle fill="#f197be" class="cls-9" cx="64.42" cy="235.5" r="5.15"></circle>
        <circle fill="#f197be" class="cls-9" cx="130.05" cy="235.5" r="5.15"></circle>
        <circle fill="#565656" class="cls-10" cx="123.99" cy="217.4" r="4.68"></circle>
        <circle fill="#fff" class="cls-11" cx="127.08" cy="215.92" r="1.48"></circle>
        <circle fill="#565656" class="cls-10" cx="70.49" cy="217.4" r="4.68"></circle>
        <circle fill="#fff" class="cls-11" cx="73.58" cy="215.92" r="1.48"></circle>
        <ellipse fill="#e9eff2" class="cls-12" cx="96.55" cy="297.08" rx="85.78" ry="4.55"></ellipse>
        <path fill="#fff" class="cls-11" d="M55.7 195.16H35.4a1 1 0 0 1 0-2h20.3a1 1 0 0 1 0 2zm104.34 79h-8.1c-.47 0-.86-.02-.86-.5s.4-.5.87-.5h8.1a2.58 2.58 0 0 0 2.26-2.53v-7.17a1 1 0 0 1 2 0v7.17c0 2.16-2.1 3.53-4.22 3.53z"></path>
        <path fill="#009688" class="cls-5" d="M18.56 160.9a1.73 1.73 0 0 1-1.18-.45l-16.05-14.8a1.74 1.74 0 0 1 2.35-2.55l16.06 14.8a1.74 1.74 0 0 1-1.18 3zm5.8-6.6a1.74 1.74 0 0 1-1.7-1.4l-2.28-11.7a1.74 1.74 0 1 1 3.4-.67l2.3 11.7a1.74 1.74 0 0 1-1.4 2.05 1.7 1.7 0 0 1-.32.03zM1.74 170a1.74 1.74 0 0 1-.16-3.47l14.55-1.37a1.74 1.74 0 0 1 .32 3.47L1.9 170h-.16z"></path>
        <text fill="#fff" font-size="58px" class="error-code" x="244" y="140">404</text>
      </svg>
      <div style={{fontSize: '4rem', margin: '4rem 0', color: '#009688'}} translate="non_branded_page_not_found_title">PAGE NOT FOUND</div>
      <div style={{lineHeight: '40px', fontSize: '19px'}} translate="non_branded_page_not_found_text">We looked everywhere for this page.
        <br />Are you sure the website URL is correct?
        <br />Get in touch with the site owner.
      </div>
      <Btn class="button ng-binding ng-scope" ng-if="!!errorPageCtrl.notBranded.cta" onClick={e => props.history.push('/home')}>Go Back Home</Btn>
    </Container>
  )
}

export default NotFound;

const Container = styled.div`
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;

  svg { max-width: 320px; }
`;

const Btn = styled.button`
  margin-top: 37px;
  outline: 0;
  height: 56px;
  letter-spacing: .8px;
  line-height: 50px;
  font-size: 19px;
  text-align: center;
  border-radius: 28px;
  cursor: pointer;
  border: 2px solid #009688;
  background-color: #fff;
  color: #009688;
  box-sizing: border-box;
  transition-property: color,background-color;
  transition-duration: .2s;
  padding: 0 20px;
  &:hover {
    color: #fff;
    background-color: #009688;
  }
`;