import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';

import { LeftTextWithImage, Text, Title, Image } from '../styles/CommonStyles';
import Footer from '../components/Footer';
import theme from '../styles/theme';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function Services(props) {
  useEffect(() => {
    document.title = "Services";
  }, []);

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        {/* <h2 style={{
          textAlign: 'center',
          paddingBottom: '1rem',
          marginTop: 0,
          paddingTop: '1rem'
        }}>Our Services</h2> */}
        <Title color={theme.primary} style={{paddingTop: '5rem', marginTop: 0}}>Household Shifting</Title>
        <LeftTextWithImage>
          <Text>
            MyMover is a leading household relocating company with presence all over India. 
            We have presence in every corner of India. MyMover Packers and Movers deals into 
            household, Corporate, Industrial Packing and Moving. We also deal into Car moving. 
            The shifting is done without any inconvenience and in a hassle free manner. With 
            our experience, expertise and market understanding, we offer fast, reliable and 
            professional packing and moving services in India.
            <br />
            <br />
            Our staff comprises of the 
            skilled labor that is trained to handle all the belongings, commercial or personal, 
            with utmost care.We take care of all the relocation details. Packing and moving 
            Services is done with the team of experts. They analyze the requirements for 
            Packing of goods & after that they recommend the same to the customers. The items 
            are well packed using the specific material for packing.
          </Text>
          <Image src={"/images/Services/household-shifting.webp"} alt="Household Shifting" />
        </LeftTextWithImage>
        <Title color={theme.primary}>Office Shifting</Title>
        <LeftTextWithImage>
          <Image src={"/images/Services/2.webp"} alt="Office shifting"  />
          <Text>
            MyMover Packers and Movers with well maintained & fully functional, facilities, 
            equipment, excellent warehouse & materials, provide comprehensive moving solutions
             & relocation services to corporate employees & their transferring families throughout 
             world. In the National Moving arena, we provide highest quality & cost effective 
             Corporate Relocation services to our clients.
             
            <br />
            <br />

            Our central focus in corporate 
             relocation is to provide business organizations with expert systems and services 
             minimize disruption to office functions and thereby to preserve the productivity 
             of employees during the relocation process. At the office relocation site all staff 
             are required to wear MyMover Packers and Movers i-card.
          </Text>
        </LeftTextWithImage>
        <Title color={theme.primary}>Car and Bike Transport</Title>
        <LeftTextWithImage>
          <Text>
            We offer the most reliable and efficient car carrier service. We handle the car with 
            utmost care so that it is free of any scar. Safety is our first priority while relocating 
            your car from one place to another. We are proud to guarantee a safe-and-sound transportation 
            of your cars. We take the help of our various car trailers so that we can transport 
            your cars all over India on a door-to-door basis.
             
            <br />
            <br />
            
            Our workers keep the trailers and 
            containerized trucks well-maintained to ensure the smooth and timely delivery of your 
            cars. So you will trust on us. Because MyMover Packers and Movers has been doing car 
            relocation for last 10 years. Our employees have proper knowledge about car carriers.
          </Text>
          <Image src={"/images/Services/3.webp"} alt="car and bike transport"  />
        </LeftTextWithImage>
        <Title color={theme.primary}>Storage</Title>
        <LeftTextWithImage>
          <Image src={"/images/Services/4.webp"} alt="Storage"  />
          <Text>
            MyMover Packers and Movers has specifically designed warehousing and storage facility 
            with properly laid out floor plan with fire prevention construction, and provided with 
            high levels of security with adequate parking facilities for all kinds of vehicles, 
            including container trucks.
            
            <br />
            <br />
            
            Our Warehouse is managed and controlled under effective 
            warehouse maintenance program and schedules related with cleaning of the premises, 
            pest control measures, fire protection procedures and other needed safety measures. 
            All appropriate warehouse handling equipment, required tools and materials are supplied 
            with, to ensure smooth loading, unloading and handling of goods/shipments.
          </Text>
        </LeftTextWithImage>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Services;

const Container = styled.div`
  width: 90vw;
  margin: auto;
  max-width: 1100px;
  padding-top: 2.7rem;
  font-family: Montserrat, serif;
  margin-bottom: 8rem;

  p {
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
    padding-top: 1rem;
  }
`;