import React, { useState } from 'react';
import styled from 'styled-components';
import uuid from 'react-uuid';

import theme from '../../styles/theme';
import { Icon } from '../../styles/CommonStyles';
import DropDownContainer from './DropDownContainer';

const textWithInputItems = ['Carton Box', 'Chair', 'Photo Frames', 'Dumbbells', 'Treadmill', 'Flower Pots', 'Computer', 
'Gas Stove', 'Mixer/Grinder', 'Geyser', 'Water Purifier', 'Gym Cycle', 'Music System', 'Induction Stove', 'Invertor/Battery'];

/**
 * Item type-1: items which has no children dropdowns
 * Item type-2: item with children at first level
 * Item type-3: item with children at second level
 */

function InnerAccordion(props) {
  const [open, setOpen] = useState(false);
  const [selectedRows, updateRows] = useState([]);

  /**
   * 1. subCategories contains normal items which can be incremented directly
   * 2. to show and handle dropdown list we use labels, titles and data
   */
  const {
    category, subCategories, labels, titles,
    data, infoText, updateData, changeitemcount, selectedItems
    // selectedItems
  } = props;

  // console.log('selectedItems===========', selectedItems);

  //  TODO: THIS IS ONE TIME WORK MAKE SURE TO SAVE INTO SOME STATE VARIABLE
  const titlesFromSubcategories = subCategories ? subCategories.map(o => `${o.title} ${o.helperText || ""}`) : [];
  // titles does not contains title for item of type 1
  const allTitles = titles ? [...titlesFromSubcategories, ...titles] : [...titlesFromSubcategories];

  const handleTitleChange = (e, rowUUID, type = 1, label1, label2, label3) => {
    // type-1, type-2, type-3 defined at the top of this file
    const newTitle = e.target.value;
    // check if the selected title is directly an item, or does it have subcategories
    // if directly an item => create new Row with item details { label1, id, rowUUID }

    const changeitemcount = (itemID) => {
      // remove this item object in selectedRows and add it with updated title
      const itemToRemove = selectedRows.find(r => r.rowUUID === rowUUID);

      // call updateData function to first decrement the item with old id and increment item with new id
      const rowDetails = { label1, label2, label3, id: itemID, rowUUID };
      let updatedSelectedRows = [...selectedRows];
      if (itemToRemove) {
        // console.log('itemToRemove', itemToRemove);
        // console.log('itemToAdd', rowDetails);
        const indexOfItem = selectedRows.findIndex(o => o.rowUUID === rowUUID);
        updateData({ type: 'decrementThenIncrement', id1: itemToRemove.id, id2: itemID });
        updatedSelectedRows[indexOfItem] = { ...rowDetails };
      } else {
        updateData({ type: 'increment', id: itemID });
        updatedSelectedRows = [...updatedSelectedRows, rowDetails];
      }
      updateRows([...updatedSelectedRows]);
    }

    // console.log('selectedRow', selectedRows, rowUUID);
    if (type === 1 && titlesFromSubcategories.includes(newTitle)) {
      const { id } = subCategories.find(o => `${o.title} ${o.helperText || ""}` === newTitle);

      changeitemcount(id);
    } else if(type === 2) {

      // handle title2 changes
      const { listItems: listItemsOfLabel2s } = data.find(o => o.title === label1);

      // find out where is label2
      const label2Item = listItemsOfLabel2s.find(o => o.helperText === label2);
      changeitemcount(label2Item.id);
    } else if(type === 3) {

      // handle title2 changes
      const data1 = data.find(o => o.title === label1);
      const data2 = data1.data.find(o => o.title === label2);

      // find out where is label2
      const label3Item = data2.listItems.find(o => o.helperText === label3);
      changeitemcount(label3Item.id);
    }
  }

  const addNewRow = () => {
    // check if all previous row are filled, then only allow to create new row
    const row = {
      id: null,
      title: "Select",
      rowUUID: uuid(),
    };
    updateRows([...selectedRows, row]);
  }

  // this is not working now
  const removeRow = (rowUUID) => {
    if(selectedRows.length) {
      const rowData = selectedRows.find(r => r.rowUUID === rowUUID);
      if(rowData) {
        const filteredRows = selectedRows.filter(r => r.rowUUID !== rowUUID);
        updateRows(filteredRows);
        updateData({ type: 'decrement', id: rowData.id });
      }
    }
  }

  // selectedItems is object with id and count of object like { 18: 2, 20: 1 }
  // console.log('selectedItems----', selectedItems);

  return (
    <InnerAccordionContainer>
      <div onClick={() => { setOpen(!open) }} style={{padding: '8px 1rem'}}>
        <p>{open ? category + (infoText || "") : category}</p>
        <Icon style={{marginRight: '18px'}} on={open} color={theme.primary} />
      </div>
      {
        open ? (
          <PaddDropDowns>
            {
              selectedRows.length > 0 ? selectedRows.map(({ label1, label2, label3, rowUUID }, i) => (
                <DropDownContainer
                  handleTitleChange={handleTitleChange}
                  allTitles={allTitles}
                  subListData={data}
                  label1={label1}
                  label2={label2}
                  label3={label3}
                  labels={labels}
                  rowUUID={rowUUID}
                  key={rowUUID + i}
                  category={category}
                  subCategories={subCategories}
                  removeRow={removeRow}
                  changeitemcount={changeitemcount}
                  textWithInputItems={textWithInputItems}
                  selectedItems={selectedItems}
                />
              )) : (
                <DropDownContainer
                  handleTitleChange={handleTitleChange}
                  allTitles={allTitles}
                  subListData={data}
                  labels={labels}
                  rowUUID={uuid()}
                  key={uuid()}
                  removeRow={removeRow}
                  category={category}
                  subCategories={subCategories}
                  changeitemcount={changeitemcount}
                  textWithInputItems={textWithInputItems}
                  selectedItems={selectedItems}
                />
              )
            }
            {
              !(textWithInputItems.includes(category)) && (
                <AddMoreLink onClick={() => addNewRow()}>+ Add more</AddMoreLink>
              )
            }
          </PaddDropDowns>
        )
        : <div></div>
      }
    </InnerAccordionContainer>
  );
}

export default InnerAccordion;

const InnerAccordionContainer = styled.div`
  border-top: 1px solid #ddd;
  padding-bottom: 1px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
      color: ${theme.primary};
      font-size: smaller;
      font-weight: bold;
    }
  }

  ${({ on }) => on && 'background: rgba(0,0,0,0.04);'}

  ul {
    list-style: none;
    border: 1px solid #ddd;
    border-bottom: none;
    display: ${({ on }) => on ? 'block' : 'none'};
    background: #fff;
    color: #000;
    margin: 1rem 1.5rem;
    padding: 0;
    max-height: 50vh;
    overflow: auto;

    li {
      padding: 4px 8px;
      padding-right: 0;
      border-bottom: 1px solid #ddd;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 0.65em;
        font-weight: bold;
      }

      & > div > div { margin-right: 8px; }
    }
  }
`;

const AddMoreLink = styled.div`
  color: #466bff;
  text-decoration: underline;
  font-size: 12px;
  font-weight: bold;
`;

const PaddDropDowns = styled.div`
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
  flex-direction:column;
  align-items: flex-start !important;
`;
