import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Auth/Authenticate';
import Loading from './Loading';

function RouteVerifier({ protectedRoute = false, ...restProps}) {
  // console.log(`route => ${restProps.path}`);

  const authContext = useContext(AuthContext);
  const { user, loading } = authContext;

  if(loading) {
    return <Loading />;
  }

  if(!protectedRoute) {
    return <Route {...restProps} />
  } else {
    const isUserAuthenticated = user && user.uid ? true : false;
    // check wheter user is authenticated...
    if(!isUserAuthenticated) {
      return <Redirect to={{ pathname: '/login' }} />
    }

    return <Route {...restProps} />
  }
}

export default RouteVerifier;