import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import theme from '../styles/theme';
import firebase from '../components/firebaseConfig';
import { AuthContext } from './Auth/Authenticate';
import { headerHeight } from '../styles/CommonStyles';
import AnimatedTextOnHover from './AnimatedTextOnHover';

function Header(props) {
  const [hamburgerActive, setHamburgerActive] = useState(false);
  const authContext = useContext(AuthContext);

  const Logout = () => {
    // log out the user...
    firebase.auth().signOut();
    props.history.push('/login');
  }

  const VisitProfile = () => {
    props.history.push('/profile');
  }

  const handleNavClick = routeName => {
    setHamburgerActive(false);
    props.history.push(routeName);
  }

  return (
    <React.Fragment>
      <StyledHeader>
        <Left>
          <Link to="/" style={{ marginRight: '3rem', fontSize: 24 }}>
            <h3>My<span id="logo">Mover</span></h3>
            {/* <img style={{height: '42px'}} src={"/images/logo_header.png"} alt="mymover logo" /> */}
          </Link>
          <Link to={`${authContext.user ? '/dashboard' : '/'}`}>
            <AnimatedTextOnHover text={authContext.user ? "YOUR ORDERS" : "HOME"}  textColor={theme.primary} />
          </Link>
          <Link to="/calculate-packers-and-movers-charges">
            <AnimatedTextOnHover text="BOOK NOW" textColor={theme.primary} />
          </Link>
          <Link to="/calculate-packers-and-movers-charges">
            <AnimatedTextOnHover text="CALCULATE COST" textColor={theme.primary} />
          </Link>
          <Link to="/services">
            <AnimatedTextOnHover text="SERVICES" textColor={theme.primary} />
          </Link>
          <Link to="/mymover-packers-and-movers-network">
            <AnimatedTextOnHover text="NETWORK" textColor={theme.primary} />
          </Link>
          <Link to="/how-mymover-packers-and-movers-work">
            <AnimatedTextOnHover text="HOW WE WORK" textColor={theme.primary} />
          </Link>
          <Link to="/about-us">
            <AnimatedTextOnHover text="ABOUT" textColor={theme.primary} />
          </Link>
          <Link to="/contact-us">
            <AnimatedTextOnHover text="CONTACT" textColor={theme.primary} />
          </Link>
        </Left>
        {
          !authContext.user && (
            <Right style={{color: '#fff', textDecoration: 'none'}}>
              <Link to="/login">
                <AnimatedTextOnHover text="Login" textColor={theme.primary} />
              </Link>
            </Right>
          )
        }
        {
          authContext.user && authContext.user.uid && (
            <Right>
              <div>
                <img src={"/images/icons/account_circle.svg"} height={"28px"} alt="profile icon" />
                <ul>
                  <li onClick={VisitProfile}>Profile</li>
                  <li onClick={Logout}>Sign out</li>
                </ul>
              </div>
            </Right>
          )
        }
      </StyledHeader>
      <MobileHeader onClick={() => setHamburgerActive(!hamburgerActive)}>
        <HamburgerContainer active={hamburgerActive} onClick={() => setHamburgerActive(!hamburgerActive)}>
          <Hamburger
            active={hamburgerActive}
            top={hamburgerActive ? "10px" : "4px"}
            theme={theme}
            color={props.color}
          />
          <Hamburger
            style={{ display: hamburgerActive ? 'none' : 'block' }}
            active={hamburgerActive}
            top={"4px"}
            styling={`border-bottom: none; z-index: 2;`}
            theme={theme}
            color={props.color}
          />
          <Hamburger
            active={hamburgerActive}
            top={hamburgerActive ? "-2px" : "4px"}
            theme={theme}
            styling={"z-index: 2;"}
            color={props.color}
          />
        </HamburgerContainer>
        {
          hamburgerActive && (
            <React.Fragment>
              <MobileDropDown id="mobile-menu">
                <ListMobile theme={theme}>
                  { authContext.user &&  <li onClick={() => handleNavClick('/profile')}>PROFILE</li>}
                  <li onClick={() => handleNavClick(firebase.auth().currentUser ? '/dashboard' : '/')}>{firebase.auth().currentUser ? "YOUR ORDERS" : "HOME"}</li>
                  <li onClick={() => handleNavClick('/calculate-packers-and-movers-charges')}>BOOK NOW</li>
                  <li onClick={() => handleNavClick('/calculate-packers-and-movers-charges')}>CALCULATE COST</li>
                  <li onClick={() => handleNavClick('/services')}>SERVICES</li>
                  <li onClick={() => handleNavClick('/mymover-packers-and-movers-network')}>NETWORK</li>
                  <li onClick={() => handleNavClick('/how-mymover-packers-and-movers-work')}>HOW WE WORK</li>
                  <li onClick={() => handleNavClick('/about-us')}>ABOUT</li>
                  <li onClick={() => handleNavClick('/contact-us')}>CONTACT</li>
                  {
                    !authContext.user
                      ? <li onClick={() => handleNavClick('/login')}>LOGIN</li>
                      : <li onClick={Logout}>LOGOUT</li>
                  }
                </ListMobile>
              </MobileDropDown>
            </React.Fragment>
          )
        }
      </MobileHeader>
    </React.Fragment>
  );
}

export default Header;

const Left = styled.div`
  display: flex;
  align-items: center;
  #logo { color: #466bff; }
  & > a {
    height: 100%;
    text-decoration: none;
    color: #000;
    & > div {
      margin-right: 2rem;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
  
      &:hover {
        & > span {
          transform: translateY(-15px);
          opacity: 0;
        }
        &:after {
          transform: translateY(0);
          opacity: 1;
        }
      }
  
      &:after {
        content: attr(data-hover-text);
        position: absolute;
        transform: translateY(15px);
        cursor: pointer;
        left: 0;
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
      }
    }
    & > div > span {
      cursor: pointer;
      transform: translateY(0);
      transition: transform 0.3s ease, opacity 0.3s ease;
    }
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  cursor: pointer;
  position: relative;
  a {
    text-decoration: none;
    color: #000;
  }

  & > div {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    background: rgba(255,255,255, 0.2);

    &:hover {
      background: rgba(255,255,255, 0.4);
    }

    & > ul {
      display: none;
      list-style: none;
      position: absolute;
      top: 28px;
      right: 30px;
      min-width: 120px;
      padding: 6px 1rem;
      background: #fff;
      box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);

      li {
        height: 30px;
        color: #000;
        padding: 0 12px;
        display: flex;
        align-items: center;
        transition-duration: 0.3s;
        font-size: 13px;

        &: hover {
          color: ${theme.pink};
          background: rgba(0,0,0,0.04);
        }
      }
    }

    &:hover {
      & > ul { display: block; }
    }
  }
`;

const StyledHeader = styled.header`
  position: fixed;
  z-index: 99;
  width: calc(100vw - 5rem);

  display: flex;
  height: ${headerHeight};
  align-items: center;
  padding-left: 5rem;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 2px;
  // background: #009688;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.12), inset 0 -1px 0 0 #dadce0;

  @media only screen and (max-width: 800px) { display: none; }
`;

const MobileHeader = styled.div`
  position: fixed;
  z-index: 99;
  width: 40px;
  height: 40px;
  padding-top: 1rem;
  left: 1rem;

  & > #mobile-menu {
    width: 110vw;
    height: calc(100vh + 1rem);
    background: #fff;
    left: -1rem;
  }

  @media only screen and (min-width: 799px) { display: none; }
`;

const HamburgerContainer = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 200;
  width: 20px;
  ${({ active }) => active && `
    & > div:nth-child(1) { transform: rotate(-45deg); }
    & > div:nth-child(3) { transform: rotate(45deg); }
  `}
`;

const Hamburger = styled.div`
  transition: transform 0.2s ease;
  border: 1px solid ${({ color }) => color ? color : theme.primary };
  background: ${({ color }) => color ? color : theme.primary };
  margin-top: ${({top}) => top || '0'};
`;

const fadeIn = keyframes`
  0% { top: -100vh; }
  100% { top: 0; }
`;

const MobileDropDown = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  animation: ${fadeIn} 0.7s cubic-bezier(0.85,-0.24, 0.07, 1.26);
`;

const ListMobile = styled.ul`
  list-style: none;
  user-select: none;
  padding: 0;

  & > li {
    font-size: 22px;
    padding: 14px 0;
    color: ${({ theme }) => theme.primary};
    font-family: Krona One, sans-serif;
  }
`;