import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from "styled-components";
// import Axios from 'axios';

import Header from '../components/Header';
import firebase from '../components/firebaseConfig';
import { AuthContext } from '../components/Auth/Authenticate';
import {
  FadeInRight,
  FlexRowCenter,
  StyledInput,
  StyledButton,
  FadeInUp,
  headerHeight,
  FormGroup,
  StyledLabel
} from '../styles/CommonStyles';
import { database } from 'firebase';
import swal from 'sweetalert';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

const decideEmailOrPhone = (text) => {
  if(!text) return "Phone number is required";
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneFormat = /^\d{10}$/;

  if (text.match(mailformat)) return "email";
  else if (text.match(phoneFormat)) return "phone";

  return "invalid phone number";
}

function Login(props) {
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  // const [typeofAuth, setTypeOfAuth] = useState(null);
  const [otpConf, setOtpConf] = useState();
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);

  const ref1 = useRef();
  const ref2 = useRef();

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('log_in_button', {
      'size': 'invisible',
      'callback': function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // console.log('recapcha done...');
        // sendMobileOtp();
        this.onSignInSubmit();
      }
    });
  }, [])

  useEffect(() => {
    if(otpSent) ref2.current.focus();
    else ref1.current.focus();
  }, [otpSent])

  // following checks are applied
  // 1. if length is from 0 to 10 digits
  // 2. if the values are digits i.e. 0-9
  // 3. allow empty strings as well
  const isPhone = (value, maxLen) => value.length <= (maxLen || 10) && (/\d$/.test(value) || value === "");

  const handlePhoneChange = e => {
    const { value } = e.target;
    if (isPhone(value)) setPhone(value);
  };
  const handleOtpChange = ({ target: {value} }) => {
    if (isPhone(value, 6)) setOtp(value);;
  };

  const submitMobileNumber = async e => {
    e.preventDefault();
    if(!loading) {  // prevent multiple click
      const type = decideEmailOrPhone(phone);
      if (type === "phone") {
        sendMobileOtp();
      } else {
        alert(type);
      }
    }
    // else if (type === "email") {
    //   // handle email auth...
    //   setOtpSent(true);
    //   setTypeOfAuth("email");
    // }
  }

  const sendMobileOtp = () => {
    setLoading(true);

    // check whether account exist with this no or not
    firebase.database().ref(`accounts/+91${phone}`).once('value', snap => {
      const data = snap.val();
      if(!data) {
        swal("You are not registered! Click OK to register.")
        .then(() => {
          props.history.push('/calculate-packers-and-movers-charges');
        })
      } else {
        const appVerifier = window.recaptchaVerifier;
        firebase.auth().signInWithPhoneNumber("+91" + phone, appVerifier)
          .then(function (confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            setLoading(false);
            setOtpSent(true);
            setOtpConf(confirmationResult);
            // setTypeOfAuth("phone");
          }).catch(function (error) {
            console.log('error', error);
            setLoading(false);
            alert(`Couldn't send OTP ${error.message}`);
          });
      }
    });
  }

  const confirmOTP = e => {
    e.preventDefault();
    if(!loading) {  // prevent multiple clicks
      setLoading(true);
      otpConf.confirm(otp).then(async function (result) {
        const user = result.user;
        // console.log('user login success', user);
        authContext.updateUserDetails(user);
        setLoading(false);

        // console.log(props);
        const locationState = props.location.state;
        if (locationState && locationState.formsPreFilled) {
          props.history.push('/item-details');
        } else {
          const uid = firebase.auth().currentUser.uid;

          // get user from /profiles
          const snap = await database().ref(`/profiles/${uid}`).once('value')
          const result = snap.val();
          // console.log('data: ', result);
          if(!result || !result.phone) {
            await database().ref(`/profiles/${uid}/phone`).set(phone);
          }

          const name = result ? result.name : null;
          const email = result ? result.email : null;
          if(!name || !email) {
            props.history.push('/calculate-packers-and-movers-charges');
          } else {
            props.history.push('/dashboard');
          }
        }
      }).catch(function (error) {
        console.log('error', error);
        setLoading(false);
        if(error.code === "auth/invalid-verification-code") {
          alert(`Incorrect OTP`);
        } else {
          alert(`Verification failed. Reason: ${error.message}`);
        }
      });
    }
  }

  // if user is already logged in get him to the dashboard...
  if(authContext.user && authContext.user.uid) {
    props.history.push('/dashboard');
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        <InnerContainer>
          {
            otpSent ? (
              <FadeInRight>
                <h2 style={{ marginBottom: 0 }}>Verify OTP</h2>
                <p style={{ marginBottom: '2.5rem' }}>OTP sent to your number</p>
                <form>
                  <FormGroup>
                    <StyledLabel>OTP</StyledLabel>
                    <StyledInput
                      placeholder={"enter one time password"}
                      value={otp}
                      onChange={handleOtpChange}
                      style={{textAlign: 'center'}}
                      ref={ref2}
                    />
                  </FormGroup>
                  <ButtonContainer>
                    <StyledButton
                      onClick={confirmOTP}
                      loading={loading}
                    >{ loading ? "Signing In..." : "Submit" }</StyledButton>
                  </ButtonContainer>
                </form>
              </FadeInRight>
            ) : (
              <FadeInUp>
                <h2 style={{ marginBottom: 0 }}>Sign in</h2>
                <p style={{ marginBottom: '2.5rem' }}>to continue to MyMover</p>
                <form>
                  <FormGroup>
                    <StyledLabel>Mobile</StyledLabel>
                    <StyledInput
                      placeholder={"Enter mobile number"}
                      value={phone}
                      onChange={handlePhoneChange}
                      ref={ref1}
                    />
                  </FormGroup>
                  <ButtonContainer>
                    <div id="log_in_button" style={{display: 'none'}}></div>
                    <StyledButton
                      onClick={submitMobileNumber}
                      loading={loading}
                      autoFocus
                    >{ loading ? "Sending OTP..." : "Next" }
                    </StyledButton>
                  </ButtonContainer>
                </form>
              </FadeInUp>
            )
          }
        </InnerContainer>
      </Container>
    </React.Fragment>
  )
}

export default Login;

const Container = styled(FlexRowCenter)`
  width: 100%;
  height: calc(100vh - ${headerHeight});
  font-family: Montserrat, sans-serif;
`;

const InnerContainer = styled(FlexRowCenter)`
  padding: 48px 40px 36px;
  max-width: 400px;
  max-height: 400px;
  border: 1.3px solid #ddd;
  border-radius: 4px;

  @media only screen and (max-width: 460px) {
    padding: 24px 24px 36px;
    border: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
`;