import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { database } from 'firebase';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../styles/theme';
import FancyButton from '../components/FancyButton';
import Loading from '../components/Loading';
import {
  BlackButton,
} from '../styles/CommonStyles';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function Jaipur(props) {
  const [network, setNetwork] = useState({});
  const [selectedCity, setCity] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "MyMover in Jaipur,Rajasthan";

    // fetch data from firebase
    database().ref('/network').once('value', snap => {
      const data = snap.val();
      setNetwork(data);
      setLoading(false);
      setCity(Object.keys(data)[0]);
    })

  }, []);

  if(loading) {
    return <Loading />
  }

  const DisplayBranchAddress = (e, city) => {
    e.preventDefault();
    if(city === "Jaipur") { 
      props.history.push(`/packers-and-movers-in-jaipur`);
      return;
    } else if(city === "Jagatpura") { 
      props.history.push(`/packers-and-movers-in-jagatpura-jaipur`);
      return;
    } else if (city === "Bangalore") {
      props.history.push(`/packers-and-movers-in-bangalore`);
      return;
    } else if (city === "Whitefield") {
      props.history.push(`/packers-and-movers-in-whitefield-bangalore`);
      return;
    } else {
      setCity(city);
      return;
    }
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '1rem',
          color: theme.primary
        }}>Packaers and Movers in Jaipur, Rajasthan</h1>
        <h3><a href="https://mymovers.in">MyMovers.in</a> | Calculate movers and packers Charges in Jaipur</h3>
        <p>
        Looking for best movers and packers service in jaipur to relocate your household, car and bike Shifting? 
        MyMover packers and movers provide <a href = "/calculate-packers-and-movers-charges">calculating moving cost
        </a> instantly and <a href="/calculate-packers-and-movers-charges">book now</a> your shifting for household 
        shifting, vehicle shifting while being in your budget. MyMover packers and movers offers fast and reliable 
        moving service with superior high quality Packing, skilled labor that is trained to handle all the belongings, 
        commercial or personal, with utmost care in Jaipur. We are one of the best reliable movers and packers in 
        jaipur. We have Moved 10000+ houses in Jaipur and rajasthan. MyMover movers and packers jaipur offers 
        value added household relocation services at Reasonable Price. Our main aim is to make shifting and relocation
        easy and hassle free for customers.
        </p>

        <p>
        Jaipur is the capital of India’s Rajasthan state. It evokes the royal family that 
        once ruled the region and that, in 1727, founded what is now called the Old City, 
        or “Pink City” for its trademark building color. At the center of its stately street 
        grid (notable in India) stands the opulent, colonnaded City Palace complex. With gardens, 
        courtyards and museums, part of it is still a royal residence.
        </p>

        <p>
        Many professional movers and packers in Jaipur Rajasthan, but its difficult to identify 
        that which is right one for you. You will get perfect House Shifting service at MyMover 
        packers and movers Jaipur at reasonable price. House Shifting to a new location in Jaipur 
        isn't a easy Work, and it become more curcial to think twice when you are shifting your 
        valuable and Precious goods. If you know what relocation services you require then you can 
        save money, by choosing MyMover packers and movers Jaipur for relocation Intracity or across india.
        </p>
        
        <StyledButton>
          <Link to="/calculate-packers-and-movers-charges">
            <BlackButton style={{textTransform: 'uppercase'}}>Calculate Moving Cost</BlackButton>
          </Link>
        </StyledButton>
        <br/>
        <h3>Jaipur Office address</h3>
        <b>MyMovers Packers and Movers</b><br/>
        <p style={{marginBottom: '3rem'}}> 
        504, Alankar Plaza, Sector 2, Central Spine, Vidyadhar Nagar,
        Jaipur, Rajasthan 302039 <br />
          <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-9166668371">+91-9166668371</a> <br />
          Email ID: <a href="mailto:info@mymover.in">info@mymover.in</a> <br />
          Website: <a href="https://mymovers.in">MyMovers.in</a>
        </p>

        <CityContainer>
        {
            Object.keys(network).map((city, index) => (
              <FancyButton
                text={city}
                key={index}
                onClick={(e) => DisplayBranchAddress(e, city)}
                active={selectedCity === city}
              />
            ))
          }
        </CityContainer>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Jaipur;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 4rem;
  font-family: Montserrat, serif;
  p {
    text-align: justify;
    text-justify: inter-word;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;

const CityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;