import React from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';

function MobileFloatingActionButtons(props) {
  return (
    <div style={{position: 'relative'}} onScroll={e => console.log(e)}>
      <Container>
      <Icon href="tel:+91-9116550911" style={{borderRight: '1px solid rgba(255,255,255,0.2)'}}>
        <img alt="PHONE CALL" src={"/images/icons/call-white.svg"} />
        <p>Phone Call</p>
      </Icon>
      <Icon href="/calculate-packers-and-movers-charges" style={{borderRight: '1px solid rgba(255,255,255,0.2)'}}>
        <img alt="FREE QUOTE" src={"/images/icons/receipt-white.svg"} />
        <p>Free Quote</p>
      </Icon>
      <Icon href="/calculate-packers-and-movers-charges" style={{borderLeft: '1px solid rgba(255,255,255,0.2)'}}>
        <img height="24px" alt="Calculate Cost" src={"/images/icons/calculator.svg"} />
        <p>Calculate Cost</p>
      </Icon>
    </Container>
    </div>
  )
}

export default MobileFloatingActionButtons;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  height: 70px;
  z-index: 99;
  width: 100vw;
  background: ${theme.black};

  display: flex;
  @media only screen and (min-width: 800px) {
    display: none;
  }
`;

const Icon = styled.a`
  width: 33.3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;

  &:hover {
    background: rgba(255,255,255,0.2);
    transition: all 0.6s ease;
  }

  p {
    font-size: 12px;
    letter-spacing: 1.2px;
    margin: 0;
    margin-top: 5px;
    font-weight: bold;
  }
`;