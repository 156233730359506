import React, { useState } from 'react';
import styled from 'styled-components';

function AnimatedInput(props) {
  const [focused, setFocused] = useState(false);
  const { value } = props;
  return (
    <InputContainer focused={focused}>
      <StyledTextInput
        id="n-email"
        type="text"
        autocomplete="on"
        name="email"
        onFocus={() => { if(!value) setFocused(!focused); }}
        onBlur={() => { if(!value) setFocused(!focused); }}
        {...props}
      />
      <label htmlFor="n-email">your email address *</label>
    </InputContainer>
  );
}

export default AnimatedInput;

const InputContainer = styled.div`
  position: relative;
  & > label {
    position: absolute;
    font-size: ${({focused}) => focused ? '11px' : '13px'};
    top: ${({focused}) => focused ? '4px' : '18px'};
    left: 16px;
    font-weight: 400;
    transition: all 0.175s ease-in-out;
    z-index: 0;
    color: rgba(255,255,255,0.7);
    cursor: text;
    user-select: none;
  }
`;

const StyledTextInput = styled.input`
  display: flex;
  align-items: center;
  min-height: 55px;
  padding: 0 15px;
  flex: 1;
  color: inherit;
  height: 100%;
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  width: 90%;
  color: rgba(255,255,255,0.9);
  letter-spacing: 0.8px;
  font-family: Montserrat, sans-serif;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
  transition: color 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
`;
