
import React from 'react';
import styled from 'styled-components';

function FancyButton(props) {
  return (
    <StyledButton {...props}>{props.text}</StyledButton>
  )
}

export default FancyButton;

const StyledButton = styled.button`
  font-family: 'Montserrat, sans-serif';
  height: ${({height}) => height ?  height : '36px'};
  min-height: 36px;
  border: none;
  user-select: none;
  background: #7E7647;
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 1rem 0.7rem;
  cursor: pointer;
  font-size: ${props => props.height ? '1.6rem' : '1.2rem'};
  font-style: italic;
  opacity: ${({ active }) => active ? 0.7 :  1}
`;