import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';

import { Title } from '../styles/CommonStyles';
import Footer from '../components/Footer';
import theme from '../styles/theme';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function HowWeWork(props) {
  useEffect(() => {
    document.title = "HowWeWork";
  }, []);

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        <Title style={{paddingTop: '5rem', marginTop: 0}} color={theme.primary}>How We Work?</Title>

        <List>
          <h3 style={{marginTop: '2rem'}}>We follow simple and hassle free process to deliver services after receive customer order.</h3>
          <ul>
            <li>After online booking, you will get call from our planning team to brief you about the shifting process</li>
            <li>Our shifting supervisor will reach at your location with the packaging team on scheduled date and time</li>
            <li>The team will pack your items by following appropriate safety protocols</li>
            <li>Supervisor will mark the items</li>
            <li>Supervisor will prepare the documents along with item list</li>
            <li>After documentation, loading will begin</li>
            <li>Supervisor will handover a copy of the documents to you</li>
            <li>Items will at reach your destination within given time</li>
            <li>Unloading team will reach along with your items and deliver your goods safely at your home</li>
          </ul>
          <p>Choose MyMover for simple, effortless and hassle free shifting. Visit <a href="/calculate-packers-and-movers-charges">Book Now</a> page to proceed further!</p>
        </List>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default HowWeWork;

const Container = styled.div`
  width: 90vw;
  margin: auto;
  font-family: Montserrat, serif;
  margin-bottom: 8rem;

  p {
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  li {
    margin: 1rem 0;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;

const List = styled.div`
  margin: auto;
  max-width: 700px;
`;