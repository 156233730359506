import React from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';

function DropDownIconAero({ on, color }) {
  return <I1 style={{marginRight: '10px'}} on={on} color={color}></I1>
}

export default DropDownIconAero;

const I1 = styled.div`
  border: solid ${theme.primary};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  color: ${({color}) => color || 'grey'};
  ${({on}) => on ? 'transform: rotate(-135deg);' : 'transform: rotate(45deg);'}
  transition: transform .333s ease-in;
  cursor: pointer;
`;