
import React, { useEffect } from 'react';
import styled from 'styled-components';

function UpIcon() {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, false);
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', () => {console.log('unmount....')}, false)
    }
  });

  const handleScroll = e => {
    if(document.querySelector('#upIcon')) {
      const { scrollY, innerHeight } = window;
      const half = innerHeight;

      // console.log(scrollY, innerHeight);
      const visibility = document.querySelector('#upIcon').style.visibility;
      if((visibility === 'hidden' || visibility === '') && scrollY > half) {
        document.querySelector('#upIcon').style.visibility = 'visible';
      }

      if(scrollY < half && visibility === 'visible') {
        document.querySelector('#upIcon').style.visibility = 'hidden';
      }
    }
  }

  const scrollToTop = () => {
    document
      .getElementById('root')
      .scrollIntoView({ behavior: "smooth" });
  }

  return (
    <GoUp id="upIcon" onClick={scrollToTop}>
      <img src={"/images/icons/upwardarrow.png"} alt="scroll to top" />
    </GoUp>
  )
}

export default UpIcon;

const GoUp =  styled.div`
  visibility: hidden;
  position: fixed;
  bottom: 1rem;
  right: 15px;
  z-index: 30;
  background-color: rgb(68 68 68);;
  width: 32px;
  height: 32px;
  opacity: .7;
  border-radius: 100%;
  padding: 6px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 800px) {
    bottom: calc(70px + 1rem);
  }
`;
