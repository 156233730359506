import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import uuid from 'react-uuid';
import swal from "sweetalert";
import moment from 'moment';
import Axios from 'axios';

import { database, auth } from "firebase";

import Loading from '../components/Loading';
import { mid } from '../utils/commonFunctions';
import { StyledButton, Loader } from '../styles/CommonStyles';
import Header from "../components/Header";
import theme from "../styles/theme";
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function OrderPreview(props) {
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paying, setPaying] = useState({
    loading: false,
    orderId: null
  });

  useEffect(() => {
    //fetch order details....
    const orderID = props.match.params.id;
    const db = database();
    try {
      const uid = auth().currentUser.uid;
      db.ref(`/users/${uid}/orders/${orderID}`).once('value', snap => {
        const data = snap.val();

        // console.log('daata', data);

        // status=success&txnID=123&amount=1234
        if(window.location.search && data.payments) {
          const params = {};
          decodeURI(window.location.search.substring(1)).split('&').forEach(s => {
            params[s.split('=')[0]] = s.split('=')[1];
          });

          // console.log('params', params);

          if(params['status'] === 'success' && params['txnID'] && params['amount']) {
            setTimeout(() => {
              swal({
                title: "Order placed!",
                text: "Check order confirmation email for details.",
                icon: "success",
              });
            }, 500)
          }
        }

        setOrderData(data);
        setLoading(false);
      })
    } catch (err) {
      console.log('err', err);
      setLoading(false);
      swal(`error: ${err.message}`);
    }
  }, [props.match.params]);



  const payNow = (e, order) => {
    e.stopPropagation();

    if (!paying.loading) {
      const { orderId, uid } = order;
      setPaying({ loading: true, orderId });
      setTimeout(() => {
        const paytmOrderId = uuid();
        document.body.innerHTML += `
        <form id="dynForm" action="https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=${mid}&orderId=${paytmOrderId}" method="post">
          <p style="margin-left: 1rem">Don't press back or refresh button!</p>  
          <input type="hidden" name="q" value="a" />
          <table border="1" style="opacity: 0">
            <tbody>
              <input type="hidden" name="mid" value=${mid}>
              <input type="hidden" name="orderId" value=${paytmOrderId}>
              <input type="hidden" id="paytmToken" name="txnToken" value="">
            </tbody>
          </table>
        </form>`;

        database()
          .ref(`users/${uid}/paytmOrderIDs/${paytmOrderId}`)
          .set(orderId)
          .then(paytmOrderIdCreated => {
            const url = `https://us-central1-mymovers-c9a28.cloudfunctions.net/paytmToken?uid=${uid}&orderId=${paytmOrderId}&amount=${payableAmount}&originalOrderId=${orderId}`;
            Axios.get(encodeURI(url))
              .then(async ({ data: { status, token } }) => {

                setPaying({ loading: false, orderId: null });

                if (status === "success") {
                  document.getElementById('paytmToken').value = token;
                  document.getElementById("dynForm").submit();
                } else {
                  swal(`Error 501: Could not process payment at this time`);
                }
              })
              .catch(err => {
                setPaying({ loading: false, orderId: null });
                console.log('err in sending email', err);
              });
          })
      }, 500)
    }
  }

  if (loading) {
    return (
      <React.Fragment>
        <Header {...props} />
        <Loading />
      </React.Fragment>
    );
  }

  if (paying.loading) {
    return (
      <div style={{ margin: '1rem' }}>
        <Loader />
      </div>
    )
  }

  const {
    orderId, locationDetails, timestamp, liftAvailability,
    totalDistanceInMeters, totalPrice, loadingCharges, unloadingCharges, payableAmount,
    packagingCharges, totalVolume, transportCharges, selectedItems, payments
  } = orderData || {};
  const paymentObj = payments && Object.values(payments).find(p => p.STATUS === "TXN_SUCCESS");
  const paymentAmount = payments ? Object.values(payments).filter(p => p.STATUS === "TXN_SUCCESS").reduce((acc, val) => acc += parseFloat(val.TXNAMOUNT).toFixed(2), 0) : 0;

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        {
          orderData ? (
            <React.Fragment>
              <Details>
                <h3>Order Details</h3>
                {
                  !paymentObj && (
                    <PayBtnContainer style={{marginBottom: '1rem'}}>
                      <StyledButton
                        onClick={e => payNow(e, orderData)}
                        style={{height: '25px', minWidth: '80px', fontSize: '13px', background: theme.pink}}
                        disabled={paying.loading}
                        > {paying.loading && paying.orderId === orderId ? "Paying..." : "Pay Now"}</StyledButton>
                    </PayBtnContainer>
                  )
                }
                <table>
                  <tbody>
                    <tr>
                      <td>ID</td>
                      <td>#{orderId}</td>
                    </tr>
                    <tr>
                      <td>FROM</td>
                      <td>{locationDetails['from-location']['description']}</td>
                    </tr>
                    <tr>
                      <td>TO</td>
                      <td>{locationDetails['to-location']['description']}</td>
                    </tr>
                    <tr>
                      <td>Moving Date</td>
                      <td>{moment(locationDetails['movingDate']).format('DD-MM-YYYY')}</td>
                    </tr>
                    <tr>
                      <td>Moving from</td>
                      <td>{liftAvailability['currentLocationFloor']} floor with lift {liftAvailability['currentLocationLift']}</td>
                    </tr>
                    <tr>
                      <td>Moving to</td>
                      <td>{liftAvailability['movingLocationFloor']} floor with lift {liftAvailability['movingLocationLift']}</td>
                    </tr>
                    <tr>
                      <td>Order Date</td>
                      <td>{moment(timestamp).format('DD-MM-YYYY')}</td>
                    </tr>
                    <tr>
                      <td>BHK</td>
                      <td>{locationDetails['shifting-bhk']}</td>
                    </tr>
                    <tr>
                      <td>Distance</td>
                      <td>{(totalDistanceInMeters * 0.001).toFixed(2)} KM</td>
                    </tr>
                    <tr>
                      <td>Volume</td>
                      <td>{totalVolume} CuFt.</td>
                    </tr>
                    <tr>
                      <td>Payment Status</td>
                      <td style={{ color: paymentObj ? 'green' : 'red' }}>{paymentObj ? 'SUCCESS' : 'FAILED'}</td>
                    </tr>
                    {
                      paymentAmount > 0 && (
                        <tr>
                          <td>Amount Paid</td>
                          <td>₹ {parseFloat(paymentAmount).toFixed(2)}</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </Details>
              <Details style={{ maxHeight: '60vh' }}>
                <h3>Items</h3>
                <table>
                  <thead>
                    <tr style={{ fontSize: '12px' }}>
                      <th style={{ textAlign: 'left' }}>Name Of Item</th>
                      <th style={{ textAlign: 'left' }}>Quanity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      selectedItems && selectedItems.map(item => (
                        <tr>
                          <td>{item.name}</td>
                          <td>{item.qty}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </Details>
              <Details>
                <h3 style={{ marginBottom: 0 }}>Charges</h3>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ paddingTop: '1rem' }}></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Transport Charges</td>
                      <td>₹ {(transportCharges).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Packaging Charges</td>
                      <td>₹ {(packagingCharges).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Loading Charges</td>
                      <td>₹ {(loadingCharges).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Unloading Charges</td>
                      <td>₹ {(unloadingCharges).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: '1rem' }}></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>₹ {(parseFloat(totalPrice)).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>GST</td>
                      <td>₹ {(parseFloat(totalPrice) * 0.18).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>₹ {(parseFloat(totalPrice) * 1.18).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Payable Amount</td>
                      <td>₹ {parseFloat(payableAmount || 0).toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </Details>
            </React.Fragment>
          ) : <h1>404 Not Found</h1>
        }
      </Container>
    </React.Fragment>
  );
}

export default OrderPreview;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding: 10vh 10vw 0  10vw;
  margin-bottom: 10vh;

  @media only screen and (max-width: 800px) {
    width: calc(100% - 2rem);
    padding: 10vh 1rem 0  1rem;
    grid-template-columns: 1fr;
  }
`;

const Details = styled.div`
  padding: 1rem;
  line-height: 1.5rem;
  width: 90%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin-bottom: 2rem;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  table {
    width: 100%;
    tbody > tr > td:first-child {
      font-size: 12px;
      min-width: 100px;
    }
    tbody > tr > td:last-child {
      font-weight: 600;
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

const PayBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;