import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyBh7_0HEsQ8hUs3EXomIQ7q8QNOWZaabcs",
    authDomain: "mymovers-c9a28.firebaseapp.com",
    databaseURL: "https://mymovers-c9a28-default-rtdb.firebaseio.com",
    projectId: "mymovers-c9a28",
    storageBucket: "mymovers-c9a28.appspot.com",
    messagingSenderId: "701783417469",
    appId: "1:701783417469:web:f43a5c94e8cb6f64718714",
    measurementId: "G-G07Y91L121"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;