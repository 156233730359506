import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import Axios from 'axios';
import firebase, { auth } from 'firebase';

import Header from '../components/Header';
import swal from 'sweetalert';
import {
  FlexRowCenter,
  StyledInput,
  StyledButton,
  FadeInUp,
  headerHeight,
  FormGroup,
  StyledLabel
} from '../styles/CommonStyles';
import Loading from '../components/Loading';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function Profile(props) {
  const [nameErr, setNameErr] = useState(null);
  const [emailErr, setEmailErr] = useState(null);

  const [profile, setProfile] = useState({ name: '', email: '' });
  const [noProfileEarlier, setNoProfile] = useState(false);

  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const mainRef = useRef();

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if(!fetching) mainRef.current.focus();
  }, [fetching])

  const getProfile = async () => {
    const uid = firebase.auth().currentUser.uid;
    const snap = await firebase.database().ref(`/profiles/${uid}`).once('value');
    const profile = snap.val();
    // console.log(props.location.state);
    if(!profile || !profile.name || !profile.email) {
      setNoProfile(true);
    }
    setProfile(profile);
    setFetching(false);
  }

  const validateForm = () => {
    let isValidated = true;
    const { name, email } = profile;
    // console.log('name, email', name, email);

    // validate name
    if(!name || name.length === 0) {
      setNameErr('name required');
      isValidated = false;
    } else if(name.length < 3) {
      setNameErr('name too short');
      isValidated = false;
    }

    // validate email
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(!email || email.length === 0) {
      setEmailErr('email required');
      isValidated = false;
    } else if(!mailformat.test(email)) {
      setEmailErr('invalid email');
      isValidated = false;
    }

    return isValidated;
  }

  const saveProfile = async e => {
    e.preventDefault();
    if(!loading) {  // prevent multiple click
      // ...
      setNameErr(null);
      setEmailErr(null);

      const isValidated = validateForm();
      if(isValidated) {
        try {
          setLoading(true);
          const uid = firebase.auth().currentUser.uid;
          const { name, email } = profile;

          // save name, email
          await firebase.database().ref(`/profiles/${uid}`).update({
            name,
            email
          });

          if(noProfileEarlier) {
            // send signup email
            let funcUrl = `https://us-central1-mymovers-c9a28.cloudfunctions.net/sendSignupEmailTest?phone=${auth().currentUser.phoneNumber}&name=${name}&email=${email}`;
            // console.log(funcUrl);
            Axios.get(funcUrl)
              .then(res => {
                // mail sent

                swal('Your profile details saved successfully! Click OK to continue.')
                .then(() => {
                  const locationState = props.location.state;
                  if(locationState && locationState.redirectUrl) {
                    props.history.push(locationState.redirectUrl);
                  } else {
                    props.history.push('/dashboard');
                  }
                })
                setLoading(false);
              })
              .catch(err => {
                console.log('err: signup mail error');
              });
          } else {
            swal('Your profile details saved successfully!')
              .then(() => {
                const locationState = props.location.state;
                if(locationState && locationState.redirectUrl) {
                  props.history.push(locationState.redirectUrl);
                } else {
                  props.history.push('/dashboard');
                }
                setLoading(false);
              })
          }
        } catch(err) {
          console.log('error in saving profile', err);
          swal(`err: ${err.message}`);
          setLoading(false);
        }
      }
    }
  }

  if(fetching) return <Loading />

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        <InnerContainer>
          <FadeInUp>
            <h2 style={{ marginBottom: 0 }}>Profile</h2>
            <p style={{ marginBottom: '2.5rem' }}>Enter your profile details</p>
            <form>
              <FormGroup>
                <StyledLabel>Name</StyledLabel>
                <StyledInput
                  placeholder={"Enter your name"}
                  value={profile.name}
                  onChange={({target: {value: name}}) => setProfile({...profile, name})}
                  ref={mainRef}
                />
              </FormGroup>
              <ErrorText>{nameErr}</ErrorText>

              <FormGroup>
                <StyledLabel>Email</StyledLabel>
                <StyledInput
                  placeholder={"Enter your email"}
                  value={profile.email}
                  onChange={({target: {value: email}}) => setProfile({...profile, email})}
                />
              </FormGroup>
              <ErrorText>{emailErr}</ErrorText>

              <ButtonContainer>
                <div id="log_in_button" style={{display: 'none'}}></div>
                <StyledButton
                  onClick={saveProfile}
                  loading={loading}
                  autoFocus
                >
                  { loading ? "Saving..." : "Save" }
                </StyledButton>
              </ButtonContainer>
            </form>
          </FadeInUp>
        </InnerContainer>
      </Container>
    </React.Fragment>
  )
}

export default Profile;

const Container = styled(FlexRowCenter)`
  width: 100%;
  height: calc(100vh - ${headerHeight});
  font-family: Montserrat, sans-serif;
`;

const InnerContainer = styled(FlexRowCenter)`
  flex: 1;
  padding: 48px 40px 36px;
  max-width: 400px;
  max-height: 400px;
  border: 1.3px solid #ddd;
  border-radius: 4px;

  @media only screen and (max-width: 460px) {
    padding: 24px 24px 36px;
    border: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
`;

const ErrorText = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: red;
  margin-top: 2px;
`;