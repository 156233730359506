import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import uuid from 'react-uuid';
import Axios from 'axios';
import swal from '@sweetalert/with-react';

import { database, auth } from 'firebase';

import Header from '../components/Header';
import Loading from '../components/Loading';
import { mid } from '../utils/commonFunctions';
import { PinkButton, StyledButton, Loader } from '../styles/CommonStyles';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function Dashboard(props) {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paying, setPaying] = useState({
    loading: false,
    orderId: null
  });

  useEffect(() => {
    try {
      const uid = auth().currentUser.uid;
      database().ref(`/users/${uid}/orders`).once('value', snap => {
        const orders = snap.val();
        setOrders(orders);
        setLoading(false);
      });
    } catch(err) {
      console.log('err in fetching orders', err);
      setLoading(false);
    }
  }, []);

  // const getProfile = async () => {
  //   const uid = auth().currentUser ? auth().currentUser.uid : null;
  //   if(uid) {
  //     const snap = await database().ref(`/profiles/${uid}`).once('value');
  //     const profile = snap.val();
  //     if(!profile || !profile.name || !profile.email) {
  //       props.history.push('/profile');
  //     }
  //   } else {
  //     return {};
  //   }
  // }

  const showOrder = (orderId) => {
    props.history.push(`/orders/${orderId}`);
  };

  const newOrder = () => {
    // Object.keys(localStorage).forEach((k) => localStorage.removeItem(k));
    props.history.push('/calculate-packers-and-movers-charges');
  };

  const payNow = (e, order) => {
    e.stopPropagation();
    // console.log(order);

    if(!paying.loading) {
      const { orderId, uid, payableAmount } = order;
      setPaying({ loading: true, orderId });

      const paytmOrderId = uuid();
      // console.log('orderID', paytmOrderId);
      setTimeout(() => {
        document.body.innerHTML += `
        <form id="dynForm" action="https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=${mid}&orderId=${paytmOrderId}" method="post">
          <p style="margin-left: 1rem">Don't press back or refresh button!</p>  
          <input type="hidden" name="q" value="a" />
          <table border="1" style="opacity: 0">
            <tbody>
              <input type="hidden" name="mid" value=${mid}>
              <input type="hidden" name="orderId" value=${paytmOrderId}>
              <input type="hidden" id="paytmToken" name="txnToken" value="">
            </tbody>
          </table>
        </form>`;

        database()
          .ref(`users/${uid}/paytmOrderIDs/${paytmOrderId}`)
          .set(orderId)
          .then(paytmOrderIdCreated => {
            const url = `https://us-central1-mymovers-c9a28.cloudfunctions.net/paytmToken?uid=${uid}&orderId=${paytmOrderId}&amount=${payableAmount}&originalOrderId=${orderId}`;
            Axios.get(encodeURI(url))
              .then(async ({ data: { status, token } }) => {
    
                setPaying({ loading: false, orderId: null });
                // console.log('status, token => ', status, token);
    
                if(status === "success") {
                  document.getElementById('paytmToken').value = token;
                  document.getElementById("dynForm").submit();
                } else {
                  swal(`Error 501: Could not process payment at this time`);
                }
              })
              .catch(err => {
                setPaying({ loading: false, orderId: null });
                swal(err.message);
                console.log('err in sending email', err);
              });
          })
          .catch(err => {
            swal(err.message);
          })

      }, 500)
    }
  }

  if(paying.loading) {
    return (
      <div style={{margin: '1rem'}}>
        <Loader />
      </div>
    )
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      {
        loading ? <Loading /> : (
          <Container>
            <TopRow>
              <h1 style={{ marginTop: '1rem' }}>Orders</h1>
              <div onClick={newOrder}>
                <PinkButton>+ New Order</PinkButton>
              </div>
            </TopRow>
            <OrderContainer>
              {
                orders && Object.keys(orders).length > 0 ? Object.keys(orders).map(key => {
                  const { orderId, totalPrice, locationDetails, payments } = orders[key];
                  const paymentObj = payments && Object.values(payments).find(p => p.STATUS === "TXN_SUCCESS");
                  return (
                    <OrderCard key={orderId} onClick={e => showOrder(orderId)}>
                      {
                        !paymentObj && (
                          <PayBtnContainer style={{marginBottom: '1rem'}}>
                            <StyledButton
                              onClick={e => payNow(e, orders[key])}
                              customStyles={`height: 25px; min-width:80px; font-size:13px;`}
                              loading={paying.loading}
                              > {paying.loading && paying.orderId === orderId ? "Redirecting..." : "Pay Now"}</StyledButton>
                          </PayBtnContainer>
                        )
                      }
                      <table>
                        <tbody>
                          <tr>
                            <td style={{paddingRight: '1rem'}}>Reference ID</td>
                            <td><b>#{orderId}</b></td>
                          </tr>
                          <tr>
                            <td style={{paddingRight: '1rem'}}>From</td>
                            <td>{locationDetails['from-location']['description']}</td>
                          </tr>
                          <tr>
                            <td style={{paddingRight: '1rem'}}>To</td>
                            <td>{locationDetails['to-location']['description']}</td>
                          </tr>
                          <tr>
                            <td style={{paddingRight: '1rem'}}>Total Price</td>
                            <td>₹{parseFloat(totalPrice * 1.18).toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </OrderCard>
                  )
                }) : (
                  <h4>No orders to preview! <br /> Please place an order to track the status.</h4>
                )
              }
            </OrderContainer>
          </Container>
        )
      }
    </React.Fragment>
  )
}

export default Dashboard;

const Container = styled.div`
  width: calc(100% - 2rem);
  padding: 1rem;
  padding-top: 10vh;
  max-width: 1300px;
  margin: 0 auto;

  @media only screen and (max-width: 600px) {
    padding-top: 1rem;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.7rem;

  @media only screen and (max-width: 600px) {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
  }
`;

const OrderContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.7rem;

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const OrderCard = styled.div`
  cursor: pointer;
  line-height: 1.4rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  table > tbody > tr {
    td:first-child {
      font-size: 12px;
      min-width: 100px;
    }
    td:last-child {
      font-weight: 600;
      font-weight: 600;
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 600px) {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  padding: 1.2rem 1rem;
`;

const PayBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;