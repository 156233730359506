import React, { useState } from 'react';
import styled from 'styled-components';
import AnimatedInput from './StyledTextInput';
import AnimatedTextOnHover from './AnimatedTextOnHover';
import theme from '../styles/theme';
import { Link } from 'react-router-dom';

import UpIcon from './UpIcon';

function Footer(props) {
  const [email, setEmail] = useState("");
  return (
    <Container theme={theme}>
      <Content>
        <Left>
          <div>SUBSCRIBE TO OUR</div>
          <LeftBottom>
            <p>newsletter</p>

            <AnimatedInput
              type="text"
              autocomplete="on"
              name="email"
              value={email}
              onChange={({target: { value }}) => { setEmail(value) }}
              plaholderText={"your email address *"}
            />

            <StyledLabel>
              <input type="checkbox" name="privacy" value="true" id="n-check" />
              <label htmlFor="n-check">
                <strong>&nbsp; I have read and agree to the privacy policy *</strong>
              </label>
            </StyledLabel>
          </LeftBottom>
        </Left>
        <Right>
          <div>
            <RightTop>QUICK LINKS</RightTop>
            <RightBottom>
              <Link to="/login">
                <AnimatedTextOnHover hoverColor={"#fff"} text={"Login"} styling={'margin-right: 0;'} />
              </Link>
              <Link to="/calculate-packers-and-movers-charges">
                <AnimatedTextOnHover hoverColor={"#fff"} text={"Book Now"} styling={'margin-right: 0;'} />
              </Link>
              <Link to="/calculate-packers-and-movers-charges">  
                <AnimatedTextOnHover hoverColor={"#fff"} text={"Calculate Cost"} styling={'margin-right: 0;'} />
              </Link>
              <Link to="/about-us">
                <AnimatedTextOnHover hoverColor={"#fff"} text={"About"} styling={'margin-right: 0;'} />
              </Link>
              <Link to="/contact-us">
                <AnimatedTextOnHover hoverColor={"#fff"} text={"Contact"} styling={'margin-right: 0;'} />
              </Link>
            </RightBottom>
          </div>
          <div>
            <RightTop>GET INSPIRED</RightTop>
            <RightBottom>
              <Link to="/services">
                <AnimatedTextOnHover hoverColor={"#fff"} text={"Services"} />
              </Link>
              <Link to="/mymover-packers-and-movers-network">
                <AnimatedTextOnHover hoverColor={"#fff"} text={"Network"} />
              </Link>
              <Link to="/">
                <AnimatedTextOnHover hoverColor={"#fff"} text={"Home"} />
              </Link>
            </RightBottom>
          </div>
          <div>
            <RightTop>FOLLOW US ON</RightTop>
            <RightBottom>
              <a href="https://m.facebook.com/#!/www.mymover.in/?ref=bookmarks" >
                <AnimatedTextOnHover hoverColor={"#fff"} text={"Facebook"} /></a>
              <a href="https://www.youtube.com/channel/UCE6ypXidAvsNToi_Bhe2FLg" >
                <AnimatedTextOnHover hoverColor={"#fff"} text={"YouTube"} /></a>
              <AnimatedTextOnHover hoverColor={"#fff"} text={"Instagram"} />
              <AnimatedTextOnHover hoverColor={"#fff"} text={"Twitter"} />
            </RightBottom>
          </div>
        </Right>
      </Content>

      <LowerContent>
        <div>
          <AnimatedTextOnHover hoverColor={"#fff"} text={"Privacy & Cookies"} />
          <AnimatedTextOnHover hoverColor={"#fff"} text={"Terms & Conditions"} />
        </div>
        <div>
          <span style={{ fontSize: '13px' }}>© 2020 - MyMover</span>
        </div>
      </LowerContent>
      <UpIcon />
    </Container>
  )
}

export default Footer;

const Container = styled.footer`
  padding: 4rem 0 2rem;
  color: #fff;
  font-weight: 600;
  user-select: none;
  background-color: ${({ theme }) => theme.primary};
  @media only screen and (max-width: 650px) {
    padding: 2rem 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  padding: 0 4rem;
  @media only screen and (max-width: 800px) { padding: 0 1rem; }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  font-size: 13px;
  font-weight: 600;
  width: 35%;

  @media only screen and (max-width: 900px) {
    min-width: 35%;
    padding-right: 2rem;
  }
  @media only screen and (max-width: 500px) { width: 90%; }
`;

const Right = styled.div`
  display: grid;
  width: 65%;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 2.5rem;
  margin-left: 8rem;
  font-weight: 600;
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    margin-top: 3rem;
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (max-width: 500px) { width: 100%; }
`;

const RightTop = styled.div`
  margin-bottom: 2rem;
  font-size: 14px;
  @media only screen and (max-width: 1000px) {
    margin-bottom: 1.5rem;
  }
`;

const RightBottom = styled.div`
  display: grid;
  grid-gap: 32px;
  color: rgba(255, 255, 255, 0.7);
  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7);
  }
  @media only screen and (max-width: 600px) { grid-gap: 24; }
`;

const LeftBottom = styled.div`
  & > p {
    font-family: Krona One, sans-serif;
    font-size: 20px;
    font-weight: normal;
    margin: 1.5rem 0 1rem;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
`;

const LowerContent = styled.div`
  display: flex;
  margin-top: 2.5rem;
  padding: 0 4rem;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
  & > div {
    display: flex;
  }
  @media only screen and (max-width: 800px) {padding: 0 1rem; }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    & > div { margin-bottom: 8px; }
  }
`;