import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { database } from 'firebase';

import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../styles/theme';
import FancyButton from '../components/FancyButton';
import Loading from '../components/Loading';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function OurNetwork(props) {
  const [network, setNetwork] = useState({});
  const [selectedCity, setCity] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Our network";

    // fetch data from firebase
    database().ref('/network').once('value', snap => {
      const data = snap.val();
      setNetwork(data);
      setLoading(false);
      setCity(Object.keys(data)[0]);
    })

  }, []);

  if(loading) {
    return <Loading />
  }

  const DisplayBranchAddress = (e, city) => {
    e.preventDefault();
    if(city === "Jaipur") { 
      props.history.push(`/packers-and-movers-in-jaipur`);
      return;
    }  else if(city === "Jagatpura") { 
      props.history.push(`/packers-and-movers-in-jagatpura-jaipur`);
      return;
    } else if (city === "Bangalore") {
      props.history.push(`/packers-and-movers-in-bangalore`);
      return;
    } else if (city === "Whitefield") {
      props.history.push(`/packers-and-movers-in-whitefield-bangalore`);
      return;
    } else {
      setCity(city);
      return;
    }
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '3rem',
          color: theme.primary
        }}>Our Network</h1>
        <h3>MyMover in India</h3>
        <p>
          India being a developing country has been experiencing several moves every 
          year. Originally from India, MyMover Packers and Movers is the trusted company 
          providing efficient packing and moving services and serving the industry since 
          its inception, almost three decades. From household relocation, office relocation, 
          corporate relocation, vehicle transportation, pet and plant relocation to 
          warehousing and storage services, we are capable and experienced enough to 
          serve you best as per your requirements. In a very less time, we have held a 
          strong place in the industry with the help of our well-established network of 
          75 branches in all over India. With this networked and connected branches, we 
          are capable to serve near about 1264 location in the country. We have been 
          maintaining our presence all across India.
        </p>

        <p>
          Our Services are available in Jaipur, Ajmer, Alwar, Banswara, Baran, Barmer, 
          Bharatpur, Bhilwara, Bikaner, Bundi, Chittorgarh, Churu, Dausa, Dholpur, Dungarpur, 
          Hanumangarh, Jaisalmer, Jalor, Jhalawar, Jhunjhunu, Jodhpur, Karauli, Kota, 
          Nagaur, Pali, Pratapgarh, Rajsamand, Sawai Madhopur, Sikar, Sirohi, Sri Ganganagar, 
          Tonk, Udaipur, Hyderabad, Itangar, Dispur, Patna, Raipur, Panaji, Gandhinagar, 
          Chandigarh, Shimla, Srinagar and Jammu, Ranchi, Bangalore, Thiruvananthapuram, 
          Bhopal, Mumbai, Imphal,Shillong, Aizawi, Kohima, Bhubaneshwar, Chandigarh, 
          Gangtok, Chennai, Hyderabad, Agartala, Dehradun, Lucknow, Kolkata, Port Blair, 
          Silvassa, Daman, Delhi, Kavaratti and Pondicherry.
        </p>

        <h2 style={{textAlign: 'center'}}>
          Our Branches
        </h2>
        {
          selectedCity && network[selectedCity] && (
            <div>
              <h4>{selectedCity}</h4>
              <div style={{paddingBottom: '10px'}}>{network[selectedCity]['subtitle']}</div>
              <div style={{paddingBottom: '10px'}}>{network[selectedCity]['address']}</div>
              <div>
                Contact: &nbsp;
                <a href={`tel:${network[selectedCity]['phone']}`}>{network[selectedCity]['phone']}</a>
              </div>
            </div>
          )
        }
        <CityContainer>
          {
            Object.keys(network).map((city, index) => (
              <FancyButton
                text={city}
                key={index}
                onClick={(e) => DisplayBranchAddress(e, city)}
                active={selectedCity === city}
              />
            ))
          }
        </CityContainer>
      </Container>
      <Footer />
    </React.Fragment>
  )
}



export default OurNetwork;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 4rem;
  font-family: Montserrat, serif;
  p {
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;

const CityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;