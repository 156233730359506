import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon } from '../../styles/CommonStyles';
import InnerAccordion from './InnerAccordion';

function Accordion({heading, data, updateData, changeitemcount, selectedItems}) {
  const [open, setOpen] = useState(false);

  return (
    <AccordionContainer open={open}>
      <AccordionHeading onClick={() => setOpen(!open)}>
        <h3>{heading}</h3>
        <Icon style={{marginRight: '10px'}} on={open} />
      </AccordionHeading>
      <AccordionBody open={open}>
        { data.map((values, i) => (
          <InnerAccordion
            key={`cat-${heading}-${i}`}
            {...values}
            index={i}
            selectedItems={selectedItems}
            updateData={updateData}
            changeitemcount={changeitemcount}
          />
        )) }
      </AccordionBody>
    </AccordionContainer>
  )
}

export default Accordion;

const AccordionContainer = styled.div`
  border: 1px solid #ddd;

  user-select: none;
  margin-bottom: 2rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
`;

const AccordionHeading = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`;

const AccordionBody = styled.div`
  display: ${({ open }) => open ? 'block' : 'none'};
`;