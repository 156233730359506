import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Contact(props) {
  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  return (
    <React.Fragment>
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '2rem'
        }}>Contact Us</h1>
         <h3>MyMovers Packers and Movers</h3>


         <b>Jaipur, Rajasthan Office address</b> <br />
        <p style={{marginBottom: '3rem'}}>    
        <b>MyMovers Packers and Movers</b><br/>
        504 ALANKAR PLAZA, CENTRAL SPINE, VIDYADHAR NAGAR, <br/>
        JAIPUR, RAJASTHAN - 302039 <br />
        <b>Contact</b><br/>
        Mobile No.: <a href="tel:+91-9166668371">+91-9166668371</a> <br />
        Email ID: <a href="mailto:info@mymovers.in">info@mymovers.in</a> <br />
        Website: <a href="https://mymovers.in">MyMovers.in</a>
        </p>


        <b>Jagatpura Jaipur, Rajasthan Office address</b> <br />
        <p style={{marginBottom: '3rem'}}>    
        <b>MyMovers Packers and Movers</b><br/>
        214, VISHWA VIDHYALAYA NAGAR, VISHWAVIDHYALAYA NAGAR, <br/>
        JAGATPURA, JAIPUR, RAJASTHAN - 302017 <br />
        <b>Contact</b><br/>
        Mobile No.: <a href="tel:+91-9166668370">+91-9166668370</a> <br />
        Email ID: <a href="mailto:info@mymovers.in">info@mymovers.in</a> <br />
        Website: <a href="https://mymovers.in">MyMovers.in</a>
        </p>


        <b>Whitefield, Bangalore Office address</b> <br />
        <p style={{marginBottom: '3rem'}}>    
        <b>MyMovers Packers and Movers</b><br/>
        156, Nandishwara Nilaya, 3rd Cross, Vinayakanagara, <br />
        Whitefield, Bengaluru, Karnataka – 560066   <br />
        <b>Contact</b><br/>
        Mobile No.: <a href="tel:+91-9116550911">+91-9116550911</a> <br />
        Email ID: <a href="mailto:info@mymovers.in">info@mymovers.in</a> <br />
        Website: <a href="https://mymovers.in">MyMovers.in</a>
        </p>


        <b>Jaipur Warehouse Address</b> <br />
        <p style={{marginBottom: '3rem'}}>    
        <b>MyMovers Packers and Movers</b><br/>
        127, Pratap Nagar Ext. B, Delhi-Ajmer 200 Fit By-Pass,<br/>
         Jaipur, Rajasthan- 302013 <br />
        <b>Contact</b><br/>
        Mobile No.: <a href="tel:+91-9166668371">+91-9166668371</a> <br />
        Email ID: <a href="mailto:info@mymovers.in">info@mymovers.in</a> <br />
        Website: <a href="https://mymovers.in">MyMovers.in</a>
        </p>

        <iframe
          //src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d113743.909949391!2d75.7037509!3d27.0144511!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd91d37c31b954de6!2sMyMover!5e0!3m2!1sen!2sin!4v1588267603420!5m2!1sen!2sin"
          width="100%"
          height="450"
          frameBorder="0"
          style={{border: 0}}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="Location of My Mover on Google Maps"
        ></iframe>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Contact;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 4rem;
  font-family: Montserrat, serif;

  p {
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;
