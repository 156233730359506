import React, { useState } from 'react';
import styled from 'styled-components';

import { FlexRowCenter } from '../../styles/CommonStyles';

function ItemWithTextInput({ changeitemcount, subCategories, selectedItems }) {

  return (
    <React.Fragment>
      <Info>Enter quantity</Info>
      {
        subCategories.map(({ id, title }) => (
          <InputBox
            id={id}
            title={title}
            selectedItems={selectedItems}
            changeitemcount={changeitemcount}
            key={'text-input' + id}
          />
        ))
      }
    </React.Fragment>
  );
}

// let timeOut = null;

function InputBox({ title, id, selectedItems, changeitemcount }) {
  const [qty, setQty] = useState(0);
  const updateQty = ({ target: { value } }) => { setQty(value); }

  const blurFired = e => {
    console.log('blur fired for', title, e.target.value);
    changeitemcount(id, parseInt(e.target.value, 10));
  }

  return (
    <Container>
      <div className="label">{title}</div>
      {/* {console.log(`--${qty[id]}`)} */}
      <input
        type={"number"}
        value={selectedItems[id] > 0 ? (qty === "" ? qty : qty > 0 ? qty : selectedItems[id]) : qty}
        onChange={updateQty}
        onBlur={blurFired}
      />
    </Container>
  );
}

export default ItemWithTextInput;

const Container = styled(FlexRowCenter)`
  justify-content: space-between;
  padding: 1rem;
  width: calc(100% - 2rem);

  .label {
    font-size: 13px;
  }
  input {
    width: 50px;
    height: 25px;
    border: 2px solid #ddd;
    border-radius: 4px;
    padding-left: 6px;
  }
`;

const Info = styled.div`
  font-size: 12px;
  font-weight: bold;
`;