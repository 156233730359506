import React, { useState } from 'react';
import styled from 'styled-components';
import { getSubListFromLabel2, getSubList2FromLabel3 } from '../../utils/commonFunctions';
import ItemWithTextInput from './ItemWithTextInput';

function DropDownContainer({
  label1, label2, label3, labels, subListData, changeitemcount, 
  category, subCategories, textWithInputItems, selectedItems, ...props
}) {
  const [title1, setTitle1] = useState(label1);
  const [title2, setTitle2] = useState(label2);
  const [title3, setTitle3] = useState(label3);

  const [subList, setSubList] =  useState(label2 ? getSubListFromLabel2(label1, label2, subListData) : null);
  const [subList2, setSubList2] =  useState(label3 ? getSubList2FromLabel3(label1, label2, label3, subListData) : null);

  const { allTitles, handleTitleChange, rowUUID, removeRow } = props;

  // useEffect(() => {
  //   console.log('subListData****************', subListData);
  // }, []);

  const updateTitle = e => {
    const title = e.target.value;
    setTitle1(title);

    /**
     * CHECK if the new title has associated sublist with it
     * if sublist data
     * else call handleTitleChange functoin which will set the title in parent state
     */

    if(subListData) {
      const searchedSubList = subListData.find(d => d.title === title);
      // console.log('subList', searchedSubList);

      if(searchedSubList) {
        // there can be two kinds of sublist
        // one for type-2 and other for type-3
        // data structure for both are differenet
        // type-2 has listItems array directly therefore we can use it directly
        // type-3 has an array called data with following child { title: "abc", listItems: [] }
        
        if(searchedSubList.data) {
          const listItems = searchedSubList.data.map(d => ({ helperText: d.title }));
          searchedSubList['listItems'] = listItems;
        }

        setSubList(searchedSubList);
      } else {
        if(title !== "Select") {
          // console.log('title-------', title);
          handleTitleChange(e, rowUUID, 1, title, null, null);
        }
      }
    } else {
      // console.log('title-------2', title);
      handleTitleChange(e, rowUUID, 1, title, null, null);
    }
  }

  const updateSubTitle = e => {
    const title2 = e.target.value;
    setTitle2(title2);

    // identity whether there is data for type-3 as well..
    if(!subList['listItems'][0].id) { // we have no id for type-3
      const listItems = subList.data.find(d => d.title === title2);
      // console.log('yes this is fucking type 3', listItems);
      setSubList2(listItems);
    } else {
      handleTitleChange(e, rowUUID, 2, title1, title2, null)
    }
  }

  const updateSubTitle2 = e => {
    setTitle3(e.target.value);
    handleTitleChange(e, rowUUID, 3, title1, title2, e.target.value)
  }

  return  textWithInputItems.includes(category) ? (
    <ItemWithTextInput
      changeitemcount={changeitemcount}
      subCategories={subCategories}
      selectedItems={selectedItems}
    />
  ):(
    <SpaceBetween>
      <div>
        <StyledSelect style={{marginRight: '6px'}} key={rowUUID} value={title1} onChange={updateTitle}>
          <option key={"Select"} value={"Select"}>{labels ? labels[0].toUpperCase() : "Select"}</option>
          { allTitles.map(title => <option key={title} value={title}>{title}</option>) }
        </StyledSelect>
        {
          subList && subList.listItems ? (
            <React.Fragment>
              <StyledSelect style={{marginRight: '6px'}} value={title2} onChange={updateSubTitle}>
                <option key={"Select"} value={"Select"}>{labels ? labels[1].toUpperCase() : "Select"}</option>
                {
                  subList.listItems.map(({ helperText, id }, index1) => <option key={rowUUID + id + index1} value={helperText}>{helperText}</option>)
                }
              </StyledSelect>
              {
                subList2 && subList2.listItems ? (
                  <StyledSelect value={title3} onChange={updateSubTitle2}>
                    <option key={"Select"} value={"Select"}>{labels ? labels[2].toUpperCase() : "Select"}</option>
                    {
                      subList2.listItems.map(({ helperText, id }) => <option key={id} value={helperText}>{helperText}</option>)
                    }
                  </StyledSelect>
                ) : (
                  <React.Fragment></React.Fragment>
                )
              }
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )
        }
      </div>
      <div style={{color: 'red'}} onClick={() => removeRow(rowUUID)}>&#10005;</div>
    </SpaceBetween>
  );
}

export default DropDownContainer;

const StyledSelect = styled.select`
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  border: none;
  color: #000;
  padding: 4px;
  margin-top: 6px;
  min-height: 30px;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: calc(100% - 1rem);

  border-bottom: 1px solid #f0ecec;
  padding-bottom: 1rem;
`;