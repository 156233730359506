import styled, { keyframes } from "styled-components";
import theme from '../styles/theme';

export const headerHeight = '60px';

export const FadeInUpAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FadeInRightAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(150px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const FadeInLeftAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const FadeInUp = styled.div`
  animation-name: ${FadeInUpAnimation};
  animation-duration: 0.45s;
  animation-fill-mode: both;
`;

export const FadeInRight = styled.div`
  animation-name: ${FadeInRightAnimation};
  animation-duration: 0.3s;
  animation-fill-mode: both;
`;

export const StyledInput = styled.input`
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  border-radius: 4px;
  color: #202124;
  font-size: 16px;
  height: 28px;
  margin: 1px 1px 0 1px;
  padding: 13px 15px;
  border: 2px solid #ddd;
  z-index: 1;
  width: 366px;
  max-width: 80vw;
  transition: 0.1s ease-in;

  ${({ isDropdown }) => isDropdown && `
    border-radius: 4px 4px 0 0;
    border-bottom-color: #ddd;
  `}

  &:focus {
    border: 2px solid ${theme.primary};
    outline: none;
    &::placeholder { color: transparent; }
  }
`;

export const StyledSelect =  styled.select`
  border: 2px solid #dddd;
  height: 48px;
  padding: 8px 13px;
  background: #fff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  ${({value}) => !Boolean(value) && `color: #ddd`}

  option { font-size: 14px; }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
`;

export const StyledButton = styled.button`
  height: 36px;
  background-color: ${theme.primary};
  border-radius: 2.5px;
  color: #fff;
  border: none;
  min-width: 100px;
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  transition: 0.07s ease-in;
  ${({customStyles}) => customStyles || ''}

  ${({loading}) => loading && `
    opacity: 0.4;
    padding-right: 16px;
    padding-left: 16px;
  `}

  &:hover {
    ${({loading}) => !loading && `
      opacity: 0.8;
    `}
  }
`;

export const ErrorText = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: red;
  margin-top: 2px;
`
export const PinkButton = styled.button`
  background: #E91E63;
  color: #ffffff;
  padding: 12px 2rem;
  border: none;
  outline: none;
  font-weight: bold;
  height: 40px;
  border-radius: 4px;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: Montserrat, sans-serif;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transition: background-color .2s,border .2s,box-shadow .2s,-webkit-box-shadow .2s;
  &:hover {
    background: #e91e63ed;
    cursor: pointer;
  }
`;

export const BlackButton = styled.button`
  background: #000000;
  color: #ffffff;
  padding: 12px 2rem;
  border: none;
  outline: none;
  font-weight: bold;
  height: 40px;
  border-radius: 4px;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: Montserrat, sans-serif;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transition: background-color .2s,border .2s,box-shadow .2s,-webkit-box-shadow .2s;
  &:hover {
    background: #000000ed;
    cursor: pointer;
  }
`;

export const LeftTextWithImage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin: auto;

    & > img {
      margin-bottom: 1rem;
      order: -1;
    }

    & > div { text-align: justify; }

    & > div, & > img {
      width: 100%;
    }
  }
`;

export const Text = styled.div`
  width: 48%;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 28px;
  font-size: 15px;
  text-align: justify;
  text-justify: inter-word;
  animation: ${FadeInUpAnimation} 0.45s;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 2.5rem;
  color: #000;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 4rem;
  color: ${({ color }) => color || "#000"};
  @media only screen and (max-width: 800px) {
    margin-top: 5rem;
    margin-bottom: 2.5rem;
  }
`;

export const Image = styled.img`
  width: 48%;
  animation: ${FadeInUpAnimation} 0.45s;
  max-height: 50vh;
  ${({ customStyles }) => customStyles || ''}
`;

const rotate = keyframes`
  from { transform: rotate(0); }
  to { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid ${theme.primary};
  border-right-color: transparent;
  transform: translate(-50%, -50%);
  transform-origin: center;
  animation: ${rotate} 1s linear infinite;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: Montserrat, sans-serif;
  margin-top: 2rem;
`;

export const StyledLabel = styled.label`
  position: absolute;
  top: -4px;
  left: 1rem;
  z-index: 2;
  background: #fff;
  padding: 0 4px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
`;

export const Icon = styled.div`
  border: solid ${({color}) => color || '#202124'};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  color: #ddd;
  ${({on}) => on ? 'transform: rotate(-135deg);' : 'transform: rotate(45deg);'}
  transition: transform .2s ease-in-out;
`;