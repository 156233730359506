import React from 'react';
import styled from 'styled-components';

function Loading(props) {
  return (
    <Container>
      <img src={"/images/puffLoader.svg"} alt="loading..." />
    </Container>
  )
}

export default Loading;

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;